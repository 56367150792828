import React, { Component } from 'react';

import * as Survey from "survey-react";
import "survey-react/survey.css";

const surveyJSON = {
    "completedHtml": "<h3>Thank you for your feedback.</h3> <h5>Your thoughts and ideas will help us to create better instructions!</h5>",
    "completedHtmlOnCondition": [
        {
            "expression": "{nps_score} > 8",
            "html": "<h3>Thank you for your feedback.</h3> <h5>We're happy that you enjoyed your experience. Your ideas and suggestions will help us to make our instructions even better!</h5>"
        }, {
            "expression": "{nps_score} < 7",
            "html": "<h3>Thank you for your feedback.</h3> <h5> We're happy that you shared your ideas with us. We highly value all suggestions from our customers, and we will do our best to improve the instructions to reach your expectations.</h5><br/>"
        }
    ],
    "pages": [
        {
            "name": "page1",
            "elements": [
                {
                    "type": "rating",
                    "name": "nps_score",
                    "title": "On a scale of zero to ten, how helpful are these instructions?",
                    "isRequired": true,
                    "rateMin": 0,
                    "rateMax": 10,
                    "minRateDescription": "(Not helpful)",
                    "maxRateDescription": "(Extremely helpful)"
                }, {
                    "type": "comment",
                    "name": "passive_experience",
                    "visibleIf": "{nps_score} > -1  and {nps_score} < 9",
                    "title": "What is the primary reason for your score?"
                }, {
                    "type": "comment",
                    "name": "disappointed_experience",
                    "visibleIf": "{nps_score} notempty",
                    "title": "What could we do to improve your experience?"
                }, {
                    "type": "text",
                    "name": "user_email",
                    "visibleIf": "{nps_score} notempty",
                    "title": "Please provide your email if you'd like updates about these instructions or the product it self."
                }
            ]
        }
    ],
    "showQuestionNumbers": "off"
};

Survey
   .StylesManager
   .applyTheme("winter");
Survey.defaultBootstrapCss.navigationButton = "btn btn-primary";

class SurveyModal extends Component {

  constructor(props) {
    super(props);
    this.modal = React.createRef();
  }

  componentDidMount() {
    this.modal.current.addEventListener('click', this.handleModalCollapse);
  }

  handleModalCollapse = (evt) => {

    setTimeout(() => {
      if (!this.modal.current.className.includes("show")) {
        this.props.modalClosed();
      }

    }, 500);

  }

  render() {

    // === feedback survey content ===
    const surveyModel = new Survey.Model(surveyJSON);


    return (
      <div className="modal" id="surveyModal" ref={this.modal}>
        <div className="modal-dialog modal-lg">
          <div className="modal-content">

            <div className="modal-header">
              <h4 className="modal-title">Feedback Survey</h4>
              <button type="button" className="close" data-dismiss="modal">&times;</button>
            </div>

            <div className="modal-body">
              <Survey.Survey model={surveyModel} onComplete={this.props.onSurveyComplete}/>
            </div>

          </div>
        </div>
      </div>
    );
  }

}

export default SurveyModal;
