import React from 'react';
import { useLocation, Link } from 'react-router-dom';

const InstructionFooter = (props) => {

  return (
    <footer className={`${props.styles.instruction_footer}`}>
          <div className="row text-center">
            <div className="col">
              <button
               onClick={props.previous_button_pressed}
               style={props.current_step_index > 0 ? {float: 'left'} : { display: 'none', float: 'left'}}
               className={`${props.styles.image_button} ${'button_pulsate'}`}>
                <i className={`${'fas fa-arrow-circle-left'} ${props.styles.arrow_icon}`}></i>
              </button>
            </div>

            <div className="col">
              <button
               data-toggle="modal"
               data-target="#instructionMobileModal"
               className={`${props.styles.instruction_options_button} ${props.styles.image_button} ${'button_pulsate'}`}>
                <i className={`${'fas fa-bars'} ${props.styles.instruction_options_icon}`}></i>
              </button>
            </div>

            <div className="col">
              <button
              onClick={props.next_button_pressed}
              style={props.current_step_index < props.text_instruction_steps.length ? {float: 'right'} : { display: 'none', float: 'right' }}
              className={`${props.styles.image_button} ${'button_pulsate'}`}>
                <i className={`${'fas fa-arrow-circle-right'} ${props.styles.arrow_icon}`}></i>
              </button>
            </div>

          </div>
    </footer>
  );
}

export default InstructionFooter;
