import React from 'react';


const SupplementalDirectiveContent = (props) => {

  let message = props.language_object.text;

  if (message.length === 0) {
    return null;
  }

  return (
    <div className={`${'row'} ${props.styles.instructions_row}`}>
      <div className="col-12">
        <h3>Safety Info</h3>
      </div>

      <div className="col-12">
        <p className={props.styles.supplemental_directive_text}> {message} </p>
      </div>
    </div>
  )
}

export default SupplementalDirectiveContent;
