import React, { Component } from 'react';

import { setCurrentTextInstructionStepIndex, fetchCurrentTextInstructionStepIndex } from '../../../../logger/logger';

import Aux from '../../../../hoc/Aux/Aux';
import ImgsViewer from "react-images-viewer";

import CompleteSurveyView from '../../../../components/CompleteSurveyView/CompleteSurveyView';
import SafetyMessagePreview from '../../../../components/SafetyMessagePreview/SafetyMessagePreview';
import ImageGrid from '../../../../components/ImageGrid/ImageGrid';
import InstructionFooter from '../InstructionFooter/InstructionFooter';

class TextInstruction extends Component {

  state = {
    show_text_instruction: true,
    text_instruction_steps: this.props.text_instruction_steps,
    current_step_index: fetchCurrentTextInstructionStepIndex(),
    current_selected_image_index: 0,
    isOpen: false
  }

  nextButtonPressedHandler = () => {

    this.setState({show_text_instruction: false});

    setTimeout(
    function() {

      const nextStepIndex = this.state.current_step_index + 1;
      const unique_tag = this.state.text_instruction_steps.length + 1 === nextStepIndex + 1 ? "review_step" : this.state.text_instruction_steps[nextStepIndex].unique_tag
      setCurrentTextInstructionStepIndex(nextStepIndex);

      this.props.nextButtonPressed(this.state.current_step_index, nextStepIndex, unique_tag)
      this.setState({current_step_index: nextStepIndex, current_selected_image_index: 0, show_text_instruction: true});
    }.bind(this),
    350);
  }

  previousButtonPressedHandler = () => {

    this.setState({show_text_instruction: false});

    setTimeout(
    function() {
      const nextStepIndex = this.state.current_step_index - 1;
      setCurrentTextInstructionStepIndex(nextStepIndex);

      this.props.previousButtonPressed(this.state.current_step_index, nextStepIndex, this.state.text_instruction_steps[nextStepIndex].unique_tag)
      this.setState({current_step_index: nextStepIndex, current_selected_image_index: 0, show_text_instruction: true});
    }.bind(this),
    350);
  }

  imageSelectedHandler = (index) => {
    console.log("the image index is: ", index);
    const currentTextInstructionStep = this.state.text_instruction_steps[this.state.current_step_index];
    this.props.imageSelected(index, currentTextInstructionStep.images[index].file_url, currentTextInstructionStep, this.state.current_step_index);
    this.setState({current_selected_image_index: index, isOpen: true});
  }

  imageNavigatorActionHandler = (object) => {
    this.props.imageNavigationAction(object);
  }

  presentSurveyActionHandler = () => {
    this.props.presentSurveyModal();
  }

  updateCurrentImageHandler = (index) => {
    this.setState({current_selected_image_index: index});
  }

  isCurrentStepForReview = () => {
    return this.state.text_instruction_steps.length + 1 === this.state.current_step_index + 1;
  }

  gotoPrevImg = () => {
    this.buildNaviationActionObject("left");
    this.setState({current_selected_image_index: this.state.current_selected_image_index - 1});
  }

  gotoNextImg = () => {
    this.buildNaviationActionObject("right");
    this.setState({current_selected_image_index: this.state.current_selected_image_index + 1});
  }

  closeImgsViewer = () => {
    this.setState({isOpen: false});
    this.props.modalClosed(this.currentTextInstructionStep(), this.state.current_step_index);
  }

  buildNaviationActionObject = (direction) => {
    let obj = {};
    obj.direction = direction;

    const currentTextInstructionStep = this.currentTextInstructionStep();

    obj.current_text_instruction_step_id = currentTextInstructionStep.id;
    obj.current_text_instruction_step_index = this.state.current_step_index;

    const prev_img = currentTextInstructionStep.images[this.state.current_selected_image_index];
    obj.previous_image_file_url = prev_img.file_url;
    obj.previous_image_id = prev_img.id;
    obj.previous_image_index = this.state.current_selected_image_index;

    let next_img = null;

    if (direction === "right") {
      next_img = currentTextInstructionStep.images[this.state.current_selected_image_index + 1];
      obj.next_image_index = this.state.current_selected_image_index + 1;
    } else if (direction === "left") {
      next_img = currentTextInstructionStep.images[this.state.current_selected_image_index - 1];
      obj.next_image_index = this.state.current_selected_image_index - 1;
    }

    obj.next_image_file_url = next_img.file_url;
    obj.next_image_id = next_img.id;

    this.props.imageNavigationAction(obj);
  }

  currentTextInstructionStep = () => {

    return this.state.text_instruction_steps[this.state.current_step_index];
  }

  orderedImages = () => {
    let do_any_positions_match = false;
    let images = this.currentTextInstructionStep().images;

    for (var i = 0; i < images.length; i++) {
      const image = images[i];

      for (var j = 0; j < images.length; j++) {
        const image_two = images[j];

        if (image.id !== image_two.id && image.position === image_two.position) {
          do_any_positions_match = true;
        }
      }
    }

    if (do_any_positions_match === true) {
      // === if any of the positions match use the id's of the objects to determine their order ===

      var new_array = images;

      for (let i = 0; i < new_array.length; i++) {
        for (let j = 0; j < new_array.length - i - 1; j++) {
            if (new_array[j + 1].id < new_array[j].id) {
                [new_array[j + 1], new_array[j]] = [new_array[j], new_array[j + 1]];
            }
        }
      }

      return new_array;
    } else {
      return images.sort((a, b) => (a.position > b.position) ? 1 : (a.position === b.position) ? ((a.position > b.position) ? 1 : -1) : -1 );
    }
}

  render () {

    const currentStepNumber = this.state.current_step_index + 1;

    var currentTextInstructionStep = null;
    var textInstructionImages = null;
    var imgSources = [];
    var textInstructionStepText = null;
    var safety_message_content = null;
    var text_row_classes = ""

    var feedback_view = null;


    if (this.isCurrentStepForReview()) {
      feedback_view = <CompleteSurveyView present_survey={this.presentSurveyActionHandler} allow_feedback={this.props.allow_feedback} />;
    } else {
      currentTextInstructionStep = this.currentTextInstructionStep();

      var imageContent = null;

      if (this.props.show_enlarged_photos !== true) {

        textInstructionImages = this.orderedImages().map((image, index) => {
          return (
            <div key={"text_instruction_image_div" + index} className="col-6 col-md-2">
              <button
                onClick={() => this.imageSelectedHandler(index)}
                className={this.props.styles.image_button}>
  
                      <img
                      key={"text_instruction_image" + index}
                      className={`${'rounded'} ${this.props.styles.text_instruction_image}`}
                      src={image.file_url}
                      alt="Text Instruction" />
  
              </button>
            </div>
          )
        });


        imageContent = (
          <div className={`${'row'} ${this.props.styles.instruction_step_images_row}`}>
              {textInstructionImages}
            </div>
        )

      } else {
        imageContent = <ImageGrid 
                        styles={this.props.styles} 
                        images={currentTextInstructionStep.images}
                        image_selected={this.imageSelectedHandler}
                        />
      }


      imgSources = currentTextInstructionStep.images.map((image, index) => {
        return {src: image.file_url};
      });

      textInstructionStepText = this.props.step_language_objects[currentTextInstructionStep.id][this.props.current_language].text;

      text_row_classes = this.state.show_text_instruction ? `${'row'} ${this.props.styles.text_row} ${this.props.styles.show_row}` : `${'row'} ${this.props.styles.text_row}`

      if (currentTextInstructionStep.safety_message) {

        const safety_message = currentTextInstructionStep.safety_message;
        const message = safety_message.safety_language_objects[0].message;

        safety_message_content = (
          <div className={text_row_classes}>
            <div className="col-12">
              <SafetyMessagePreview
              message={message}
              safety_type={safety_message.safety_type}
              safety_message={safety_message} />
            </div>
          </div>
        )
      }

    }

    if (this.props.text_instruction_steps.length === 0) {
      return (

        <Aux>
          <div className="row text-center">
            <div className="col">
              <p>There is no Text Instruction content available.</p>
            </div>
          </div>
        </ Aux>

      )
    }

    return (
      <Aux>
        <div className="row">
          <div className={`${'col'} ${this.props.styles.step_count_column}`}>
            <p className={this.props.styles.step_count_text}>Step {currentStepNumber} <span className={this.props.styles.step_count_text_shorthand}>{currentStepNumber}/{this.state.text_instruction_steps.length + 1}</span></p>
          </div>
        </div>

        <div className={`${'row'} ${this.props.styles.button_navigation_row}`}>
          <div className="col">
            <button
             onClick={this.previousButtonPressedHandler}
             style={this.state.current_step_index > 0 ? {float: 'left'} : { display: 'none', float: 'left'}}
             className={`${this.props.styles.image_button} ${'button_pulsate'}`}>
              <i className={`${'fas fa-arrow-circle-left'} ${this.props.styles.arrow_icon}`}></i>
            </button>

            <button
            onClick={this.nextButtonPressedHandler}
            style={this.state.current_step_index < this.state.text_instruction_steps.length ? {float: 'right'} : { display: 'none', float: 'right' }}
            className={`${this.props.styles.image_button} ${'button_pulsate'}`}>
              <i className={`${'fas fa-arrow-circle-right'} ${this.props.styles.arrow_icon}`}></i>
            </button>
          </div>
        </div>

        { feedback_view }

        { safety_message_content }

        <div className={ text_row_classes }>

          <div className="col">
            <p
            className={this.props.styles.instruction_step_text}
            dangerouslySetInnerHTML={{ __html: textInstructionStepText }}>
            </p>

            {imageContent}

          </div>

        </div>

        <ImgsViewer
        backdropCloseable={true}
        currImg={this.state.current_selected_image_index}
        imgs={imgSources}
        isOpen={this.state.isOpen}
        onClickPrev={this.gotoPrevImg}
        onClickNext={this.gotoNextImg}
        onClose={this.closeImgsViewer}
        />

        <InstructionFooter
        previous_button_pressed={this.previousButtonPressedHandler}
        next_button_pressed={this.nextButtonPressedHandler}
        text_instruction_steps={this.state.text_instruction_steps} current_step_index={this.state.current_step_index} styles={this.props.styles} />

      </ Aux>
    );
  }
}

export default TextInstruction;
