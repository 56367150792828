import React from 'react';

const InstructionBottomContent = (props) => {


  return (
    <React.Fragment>

      <div className="row">
        <div className="col d-none d-md-block">

          {props.pdfInstructionButton}
          {props.textInstructionButton}
          {props.videoInstructionButton}

          <hr className={`d-none d-md-block ${props.styles.instruction_options_underline}`} />
        </div>
      </div>

      <div className={props.styles.hide_when_small}>
        {props.instructionObjectToShow}
      </div>
    </React.Fragment>
  );
}

export default InstructionBottomContent;
