import React, {Component} from 'react';

import semnarLogo from '../../../assets/resources/img/logo-white.png';
import notFoundImage from '../../../assets/resources/img/notFound.jpeg';

import styles from './NotFound.module.css';

class NotFound extends Component {

  componentWillMount() {
    document.body.classList.add('notFound_Body');
  }

  componentWillUnmount() {
    document.body.classList.remove('notFound_Body');
  }

  render() {
    return (

      <div className={`${"container"} ${styles.not_found_container}`}>
        <div className="row text-center">
          <div className="col">
            <img className={styles.semnar_logo} src={semnarLogo} alt="Semnar Logo" />
          </div>
        </div>

        <div className="row text-center">
          <div className="col-12">
            <h1>Ooops Page Not Found!</h1>
          </div>

          <div className="col-12">
            <p>We can't find the page you are looking for. Sorry for the inconvenience.</p>
          </div>
        </div>

        <div className="row">
          <div className="col">

            <div className={`${"card"} ${styles.register_login_card}`}>
              <div className="card-body">

                <img className={`${"rounded"} ${styles.not_found_image}`} src={notFoundImage} alt="Not Found" />

              </div>
            </div>

          </div>
        </div>

      </div>
    )
  }
}

export default NotFound;
