import React from 'react';

const ProductBottomContentPlaceholderRow = (props) => {

  return (
    <div className="row">
      <div className="col-12">
        <img className={props.styles.product_bottom_content_image_placeholder} src={props.image} alt="Product section placeholder"/>
      </div>
    </div>
  );
}

export default ProductBottomContentPlaceholderRow;
