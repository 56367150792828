import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import noImage from '../../../../../assets/resources/img/no-image.png';

class InstructionCard extends Component {

  handleClick = (e) => {
    if (this.props.product_code) {
      e.preventDefault();
    }
  }

  render() {

    const imageSource = this.props.instruction_object.image ? this.props.instruction_object.image.file_url : noImage;

    let language_object = null;
    let language_objects = {};

    for (var i = 0; i < this.props.instruction_object.instruction_language_objects.length; i++) {
      const languageObject = this.props.instruction_object.instruction_language_objects[i];

      language_objects[languageObject.language_code] = languageObject;
    }

    if ((this.props.current_language in language_objects)) {
      language_object = language_objects[this.props.current_language];
    } else if (("en" in language_objects)) {
      language_object = language_objects["en"];
    } else {
      language_object = this.props.instruction_object.instruction_language_objects[0];
    }

    return (
      <div className="col-12 col-md-4">

        <Link
        to={this.props.instruction_url + this.props.instruction_object.id + "?type=pdf&lang=en"}
        onClick={this.handleClick}
        className={this.props.styles.view_instruction_link}>
          <div className={`${'semnar-card default'}`}>
            <img className="card-img-top" src={imageSource} alt="Instruction Preview" />
            <div className="card-body">
              <h4>{language_object.title}</h4>
              <p className={`${'paragraph-small'} ${this.props.styles.instruction_card_description}`}>{language_object.description}</p>
              <div className="row">
              <div className="col">
                <ul className="list-group list-group-flush">
                  <li className={`${'list-group-item'} ${this.props.styles.instruction_info_list_item}`}>
                    <i className={`${'fas fa-tachometer-alt'} ${this.props.styles.instruction_info_icon}`}></i> Difficulty
                    <span className={this.props.styles.list_group_value}>
                      {this.props.instruction_object.difficulty}
                    </span>
                  </li>
                  <li className={`${'list-group-item'} ${this.props.styles.instruction_info_list_item}`}>
                    <i className={`${'far fa-clock'} ${this.props.styles.instruction_info_icon}`}></i> Duration
                    <span className={this.props.styles.list_group_value}>
                      {this.props.instruction_object.estimated_time} minute(s)
                    </span>
                  </li>
                </ul>
                </div>
              </div>

            </div>
          </div>
        </Link>

      </div>
    );
  }

}

export default InstructionCard;
