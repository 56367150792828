import React from 'react';
import { useLocation, Link } from 'react-router-dom';
import semnarLogo from '../../assets/resources/img/logo-blue.png';

import styles from './Nav.module.css';

const Nav = (props) => {
  const location = useLocation();
  const pathToInclude = ["/terms_and_conditions", "/privacy_policy"];

  if (!pathToInclude.includes(location.pathname) && !props.forCompany) {
    return null;
  }

  let headerClass = "";

  if (location.pathname === "/search") {
    headerClass = styles.search_page_header;
  } else if (location.pathname === "/company_directory" || location.pathname === "/terms_and_conditions" || location.pathname === "/privacy_policy" || location.pathname === "/about_us" || location.pathname === "/contact_us") {
    headerClass = styles.company_directory_page_header;
  } else if (location.pathname.includes("/companies/")) {
    headerClass = styles.company_page_header;
  } else if (location.pathname.includes("/products/")) {
    headerClass = styles.product_page_header;
  } else if (location.pathname.includes("/instructions/")) {
    headerClass = styles.instruction_page_header;
  }

  return (
    <header className={headerClass}>
      <div className="container">
        <nav className="navbar navbar-expand-sm navbar-toggleable-md navbar-light" role="navigation">

          <Link className="navbar-brand">
            <img className="semnar-logo" src={semnarLogo} alt="Semnar Logo" />
          </Link>

          <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarTogglerDemo01"
          aria-controls="navbarTogglerDemo01"
          aria-expanded="false"
          aria-label="Toggle navigation">

            <span className="navbar-toggler-icon"></span>

          </button>

          <div className="collapse navbar-collapse" id="navbarTogglerDemo01">
            <ul className="navbar-nav mr-auto">
              {/*
                <li className="nav-item px-2">
                  <Link to={'/'} className="nav-link semnar-btn">Home</Link>
                </li>
                <li className="nav-item px-2">
                  <Link to={'/company_directory'} className="nav-link semnar-btn">Company List</Link>
                </li>
              */}
            </ul>

            <ul className="navbar-nav mr-auto">
            {/*
              <li className="nav-item px-2">
                <Link to={'/about_us'} className="nav-link semnar-btn">About Us</Link>
              </li>
              <li className="nav-item px-2">
                <a href={'/contact_us'} className="nav-link semnar-btn">Contact Us</a>
              </li>
            */}
            </ul>
          </div>

        </nav>
      </div>
    </header>
  );
}

export default Nav;
