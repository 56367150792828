import React, { Component } from 'react';

import noImage from '../../../../assets/resources/img/no-image.png';
import ImgsViewer from "react-images-viewer";

class ProductTopContent extends Component {

  state = {
    current_selected_image_index: 0,
    isOpen: false
  }

  imageSelectedHandler = (index) => {
    this.setState({current_selected_image_index: index, isOpen: true});
  }

  gotoPrevImg = () => {
    this.setState({current_selected_image_index: this.state.current_selected_image_index - 1});
  }

  gotoNextImg = () => {
    this.setState({current_selected_image_index: this.state.current_selected_image_index + 1});
  }

  closeImgsViewer = () => {
    this.setState({isOpen: false});
  }

  render() {

    var previewLinkAlertContent = null;

    if (this.props.product_code) {
      previewLinkAlertContent = (
          <div className="col-12">
            <div className="alert alert-warning">
              <strong>This is a preview link!</strong> Only share this page with people you know.
            </div>
          </div>
      )
    }

    const mainProductImage = this.props.product.images.length > 0 ? this.props.product.images[0].file_url : noImage;

    const productImages = this.props.product.images.map((image, index) => {
      if (index === 0) {
        return null;
      }

      return (
        <div key={"product_image_" + index} className="col-2">
          <button
          onClick={() => this.imageSelectedHandler(index)}
          className={this.props.styles.image_button}>
              <img
              className={this.props.styles.product_smaller_image}
              src={image.file_url}
              alt="Product"
              onClick={() => this.props.imageSelectedHandler(index)} />
          </button>
        </div>
      );
    })

    const imgSources = this.props.product.images.map((image, index) => {
      return {src: image.file_url};
    });

    const companyLogo = this.props.company.image && !this.props.white_label_object.active ? <img className={this.props.styles.product_profile_company_logo} src={this.props.company.image.file_url} alt="honda logo" /> : null;

    let preview_content = null;

    if (this.props.product.preview_link.length > 0) {
      preview_content = (
        <a href={this.props.product.preview_link} target="_blank" className={this.props.styles.visit_product_link}>
                  <i class="fas fa-link"></i> View Product</a>
      )
    }

    return (
      <div className="row">

        { previewLinkAlertContent }

        <div className="col-12 col-md-5">
          <div className="row">
            <div className="col-12">
              <button
              onClick={() => this.imageSelectedHandler(0)}
              className={this.props.styles.image_button}>
                  <img
                  className={this.props.styles.product_main_image}
                  src={mainProductImage}
                  alt="Main product" />
              </button>
            </div>
          </div>

          <div className={`${'row'} ${this.props.styles.small_image_row}`}>
            {productImages}
          </div>
        </div>

        <div className="col-12 col-md-7">
          <div className="row">
              <div className="col">
                {companyLogo}
                {/*<a href="#" className="share-product-link"><i className="fas fa-external-link-alt"></i> Share</a>*/}
                {/*<a href="#" className="favorite-product-link"><i className="far fa-heart"></i> Add to Favorites</a>*/}
              </div>
          </div>

          <div className="row">
            <div className="col">
              <h2>{this.props.language_object.name}</h2>
              <p className={this.props.styles.product_result_category}>{this.props.product.category ? this.props.product.category.name.charAt(0).toUpperCase() + this.props.product.category.name.slice(1) : "No Cateogry"}</p>

            {preview_content}

              <p className={`${'paragraph-small'} ${this.props.styles.product_profile_description}`}>
                {this.props.language_object.description}
              </p>
            </div>
          </div>

          <div className='row'>

            <div className="col-12 col-md-3">
              <p className="paragraph-small">
                <span className={this.props.styles.count_number}>{this.props.totalInstructions}</span> <br /> <span>Total Instructions</span>
              </p>
            </div>

          </div>

          {this.props.language_content}

        </div>

        <ImgsViewer
        backdropCloseable={true}
        currImg={this.state.current_selected_image_index}
        imgs={imgSources}
        isOpen={this.state.isOpen}
        onClickPrev={this.gotoPrevImg}
        onClickNext={this.gotoNextImg}
        onClose={this.closeImgsViewer}
        />
      </div>
    );

  }

}

export default ProductTopContent;
