import React, {Component, useRef} from 'react';

import axios from 'axios';

import styles from './contentinactive.module.css';
import semnarLogo from '../../assets/resources/img/logo-white.png';

class ContentInactive extends Component {

  state = {}

  componentWillMount() {
    document.body.classList.add('notFound_Body');
  }

  componentWillUnmount() {
    document.body.classList.remove('notFound_Body');
  }

  render() {

    return (

      <div className={`${"container"} ${styles.not_found_container}`}>
        <div className="row text-center">
          <div className="col">
            <img className={styles.semnar_logo} src={semnarLogo} alt="Semnar Logo" />
          </div>
        </div>

        <div className="row text-center">
          <div className="col-12">
            <h1 className="qr-code-h1">Oh no! This {this.props.object_name} is not currently available.</h1>
          </div>

          <div className="col-12">
            <p>Please try again soon.</p>
          </div>
        </div>

        <div className="row">
          <div className="col">

            <div className={`${"card"} ${styles.register_login_card}`}>
              <div className="card-body text-center">
                <h5>Apologies for the inconvience</h5>
              </div>
            </div>

          </div>
        </div>

      </div>
    )

  }
}

export default ContentInactive;
