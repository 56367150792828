import React, {Component, useRef} from 'react';

import axios from 'axios';

import semnarLogo from '../../../assets/resources/img/logo-white.png';
import notFoundImage from '../../../assets/resources/img/notFound.jpeg';

import { getQrCodeObjectDetailsURI } from '../../../api/routes';

import styles from './qrcode.module.css';

class QrCode extends Component {

  state = {
    passed_qr_code_id: this.props.match.params.id,
    is_active: true
  }

  componentWillMount() {
    document.body.classList.add('notFound_Body');
  }

  componentDidMount() {
    this.fetchQrCode();
  }

  componentWillUnmount() {
    document.body.classList.remove('notFound_Body');
  }

  fetchQrCode = () => {

    const config = {
      "headers": {
        "Content-Type" : "application/json",
        "Accept" : "application/json",
      }
    }

    axios.get(getQrCodeObjectDetailsURI(this.state.passed_qr_code_id), config)
         .then(response => {
           if (response.data.status === "success") {

             const qr_code_object = response.data.result.qr_code_object;
             if  (qr_code_object.is_active === false || qr_code_object.redirect_route === null) {
              this.setState({is_active:  false});
            } else {
              setTimeout(
               function() {
                 window.location.replace(response.data.result.qr_code_object.redirect_route);
               }, 2000);
            }

           } else {
             console.log("there was a problem fetching the qr code");
           }

         })
         .catch(error => {
           console.log("error while fetching the qr code: ", error.message);
         })
  }

  render() {

    let content = (
      <React.Fragment>
        <div className="row text-center">
          <div className="col-12">
            <h1 className="qr-code-h1">Loading your instructions!</h1>
          </div>

          <div className="col-12">
            <p>One Sec!</p>
          </div>
        </div>

        <div className="row">
          <div className="col">

            <div className={`${"card"} ${styles.register_login_card}`}>
              <div className="card-body text-center">
                <div className="spinner-grow text-primary m-3" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
                <div className="spinner-grow text-danger m-3" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
                <div className="spinner-grow text-warning m-3" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            </div>

          </div>
        </div>
      </React.Fragment>
    )

    if (this.state.is_active ===  false) {
        content = (
          <React.Fragment>
            <div className="row text-center">
              <div className="col-12">
                <h1 className="qr-code-h1">Oh no! This QR Code is not active.</h1>
              </div>

              <div className="col-12">
                <p>Please try again soon.</p>
              </div>
            </div>

            <div className="row">
              <div className="col">

                <div className={`${"card"} ${styles.register_login_card}`}>
                  <div className="card-body text-center">
                    <h5>Apologies for the inconvience</h5>
                  </div>
                </div>

              </div>
            </div>
          </React.Fragment>
        )
    }

    return (

      <div className={`${"container"} ${styles.not_found_container}`}>
        <div className="row text-center">
          <div className="col">
            <img className={styles.semnar_logo} src={semnarLogo} alt="Semnar Logo" />
          </div>
        </div>

        { content }

      </div>
    )
  }
}

export default QrCode;
