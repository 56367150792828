import React, { Component } from 'react';

import { setCurrentVideoTime, fetchCurrentVideoTime } from '../../../../logger/logger';

import Aux from '../../../../hoc/Aux/Aux';
import InstructionFooter from '../InstructionFooter/InstructionFooter'

let timerID = null;

let seekingTimerID = null;
let isSeeking = false;
let seekingStartTime = "";
let seekingEndTime = ""

let hasStartedVideoTimeStatusTimer = false;
let videoTimeStatusTimerID = null;

let isVideoPlaying = false;

class VideoInstruction extends Component {

  constructor(props) {
    super(props);
    this.player = React.createRef();
  }

  componentDidMount() {
    if (this.props.video_instruction.video_url) {
      this.player.current.currentTime = fetchCurrentVideoTime();
      window.addEventListener("focus", this.onFocus)
      window.addEventListener('blur', this.onBlur);
    }
  }

  onFocus = () => {

    if (this.player && this.player.current) {
      const current_time = !isNaN(parseInt(this.player.current.currentTime)) ? this.player.current.currentTime : null;
      const duration = !isNaN(parseInt(this.player.current.duration)) ? this.player.current.duration : null;
      const obj = {
        instruction_type: "video",
        current_text_instruction_step_index: null,
        current_text_instruction_step_id: null,
        current_time: current_time,
        duration: duration
      }

      this.props.videoAction("view_in_focus", obj);
    }
  }

  onBlur = () => {

    if (this.player && this.player.current) {
      const current_time = !isNaN(parseInt(this.player.current.currentTime)) ? this.player.current.currentTime : null;
      const duration = !isNaN(parseInt(this.player.current.duration)) ? this.player.current.duration : null;
      const obj = {
        instruction_type: "video",
        current_text_instruction_step_index: null,
        current_text_instruction_step_id: null,
        current_time: current_time,
        duration: duration
      }

      this.props.videoAction("view_out_of_focus", obj);
    }
  }

  fireVideoTimeStatusUpdate = () => {
    videoTimeStatusTimerID = null;

    const timer = () => setTimeout(() => {

      if (isVideoPlaying && this.player.current) {
        this.sendInstructionAction("video_time_status");
      }

      this.invalidateVideoTimeStatusTimer();
    }, 3000);

    videoTimeStatusTimerID = timer();
  }

  invalidateVideoTimeStatusTimer = () => {
    clearTimeout(videoTimeStatusTimerID);
    this.fireVideoTimeStatusUpdate();
  }

  onPauseHandler = () => {
    if (isSeeking) {return}

    isVideoPlaying = false;

    const actionType = this.player.current.ended ? "video_player_ended" : "video_player_paused";
    this.sendInstructionAction(actionType);
  }

  onPlayHandler = () => {
    if (isSeeking) {return}

    isVideoPlaying = true;

    if (!hasStartedVideoTimeStatusTimer) {
      this.fireVideoTimeStatusUpdate();
      hasStartedVideoTimeStatusTimer = true;
    }

    this.sendInstructionAction("video_play_pressed");
  }

  onVolumeChangeHandler = () => {
    if (timerID !== null) {
      clearTimeout(timerID);
      timerID = null;
    }

    const timer = () => setTimeout(() => {
      const actionType = this.player.current.muted ? "video_player_muted" : "video_player_volume_changed";
      this.sendInstructionAction(actionType);
    }, 1000);

    timerID = timer();
  }

  seekingHandler = () => {
    if (!isSeeking) {
      seekingStartTime = this.player.current.currentTime;
    }

    if (seekingTimerID !== null) {
      clearTimeout(seekingTimerID);
      seekingTimerID = null;
    }

    isSeeking = true
    const timer = () => setTimeout(() => {
      seekingEndTime = this.player.current.currentTime;
      const actionType = "video_player_seeking_ended";
      this.sendInstructionAction(actionType);

      seekingTimerID = null;
      isSeeking = false;
    }, 1000);

    seekingTimerID = timer();
  }

  sendInstructionAction = (action_type) => {

    if (!isNaN(parseInt(this.player.current.currentTime))) {
      setCurrentVideoTime(this.player.current.currentTime);
    }

    const meta_data = {
      "current_time" : this.player.current.currentTime,
      "volume" : this.player.current.volume,
      "duration" : this.player.current.duration,
      "paused" : this.player.current.paused,
      "ended" : this.player.current.ended,
      "muted" : this.player.current.muted,
      "video_url" : this.props.video_instruction.video_url
    }

    if (action_type === "video_player_seeking_ended") {
      meta_data["seeking_start_time"] = seekingStartTime;
      meta_data["seeking_end_time"] = seekingEndTime;
    }

    this.props.videoAction(action_type, meta_data);
  }

  presentSurveyActionHandler = () => {
    this.props.presentSurveyModal();
  }

  render () {

    var feedback_button_content = null;

    if (this.props.allow_feedback && this.props.video_instruction.video_url) {
      feedback_button_content = (
        <div className="d-none d-md-block row">
          <div className="col text-center">
            <button
            className="button-primary xSmall"
            onClick={this.presentSurveyActionHandler}
            data-toggle="modal"
            data-target="#surveyModal"> Take Survey </button>
          </div>
        </div>
      );
    }

    if (!this.props.video_instruction.video_url) {
      return (
        <div className="col text-center">
          <p>There is no Video Instruction content available</p>
        </div>
      )
    }

    return (
      <Aux>

        { feedback_button_content }

        <div className="row">
          <div className="col">
            <video
            ref={this.player}
            className={this.props.styles.video_player}
            onPlay={this.onPlayHandler}
            onPause={this.onPauseHandler}
            onVolumeChange={this.onVolumeChangeHandler}
            onSeeking={this.seekingHandler}
            controls
            controlsList="nodownload">
              <source src={this.props.video_instruction.video_url} type="video/mp4" />
              Your browser does not support HTML5 video.
            </video>
          </div>
        </div>

        <div className="row">
          <div className="col">
            <p className={this.props.styles.instruction_step_text}>
              {this.props.language_object.text}
            </p>
          </div>
        </div>

        <InstructionFooter
        text_instruction_steps={[]}
        current_step_index={0}
        styles={this.props.styles} />
      </Aux>
    );
  }
}

export default VideoInstruction;
