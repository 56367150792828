import React from 'react';

import styles from './CompanyNav.module.css';

const CompanyNav = (props) => {

  const company_logo = props.white_label_object.images.length > 0 && props.white_label_object.active ? props.white_label_object.images[0].file_url : null;

  const header_class = props.for_instruction ? `${styles.company_page_header} ${styles.company_header_for_instruction}` : `${styles.company_page_header}`

  return (
    <header className={header_class}>
      <div className="container">
        <nav className="navbar navbar-expand-sm navbar-toggleable-md navbar-light fixed-top"
        style={{backgroundColor: "#fff"}} role="navigation">

          <a href={props.white_label_object.company_url} target="_blank" rel="noreferrer" className="navbar-brand mx-auto">
            <img className={styles.company_logo} src={company_logo} alt="Semnar Logo" />
          </a>

        </nav>
      </div>
    </header>
  );
}

export default CompanyNav;
