import axios from 'axios';
import Storage from '../Storage';
import { getUserActionsURI, getInstructionActionsURI } from '../api/routes';

const CURRENT_ENV = "production";

const USER_ACTION_TYPES = [
  "page_start",
  "user_search",
  "user_search_filter_selection",
  "user_search_more_pressed",
  "user_company_filter_selection",
  "contact_company_button_pressed"
];

const INSTRUCTION_ACTION_TYPES = [
  "page_start",
  "view_in_focus",
  "view_out_of_focus",
  "instruction_type_selection",
  "next_instruction_step_button_pressed",
  "previous_instruction_step_button_pressed",
  "instruction_step_image_selected",
  "image_navigation_button_selected",
  "image_modal_closed",
  "present_survey_button_pressed",
  "survey_modal_closed",
  "survey_was_submitted",
  "video_time_status",
  "video_play_pressed",
  "video_player_ended",
  "video_player_paused",
  "video_player_volume_changed",
  "video_player_muted",
  "video_player_seeking_ended",
  "pdf_modal_opened",
  "pdf_modal_closed",
  "pdf_zoom_in",
  "pdf_zoom_out",
  "next_pdf_page_button_pressed",
  "previous_pdf_page_button_pressed",
  "pdf_download_button_pressed"
];

export function logToConsole(message, object = "") {
  if (CURRENT_ENV === "dev") {
    console.log("==========");
    console.log(message, object);
  }
}

export function fetchUUID() {
  return Storage.uuid();
}

export function storeUserAction(actionType, url, metaData = {none: "none"}) {
  if (!USER_ACTION_TYPES.includes(actionType)) {return}

  const uuid = Storage.uuid();
  const device_timestamp = Date.now();

  const userActionObject = {
          user_action : {
            uuid : uuid,
            action_type : actionType,
            url : url,
            device_timestamp: device_timestamp,
            meta_data : metaData
          }
        }

  axios.post(getUserActionsURI(), userActionObject, {})
       .then(response => {})
       .catch(error => {
         logToConsole("error while creating user_action: ", error);
       })

}

export function storeInstructionAction(actionType, url, instruction_object_id, metaData = {none: "none"}) {

  if (!INSTRUCTION_ACTION_TYPES.includes(actionType)) { return }

  const instruction_uuid = Storage.instructionUUID();
  const uuid = Storage.uuid();
  const device_timestamp = Date.now();

  const instructionActionObject = {
          instruction_action : {
            instruction_uuid: instruction_uuid,
            instruction_object_id: instruction_object_id,
            uuid : uuid,
            action_type : actionType,
            url : url,
            device_timestamp: device_timestamp,
            meta_data : metaData
          }
    }

  axios.post(getInstructionActionsURI(), instructionActionObject, {})
       .then(response => {})
       .catch(error => {
         logToConsole("error while creating instruction_action: ", error);
       })
}

export function isSurveyComplete() {
  return Storage.isSurveyComplete();
}

export function surveyWasCompleted() {
  Storage.surveyWasCompleted();
}

export function isPdfPresented() {
  return Storage.isPdfPresented();
}

export function pdfNotPresented() {
  Storage.pdfNotPresented();
}

export function pdfPresented() {
  Storage.pdfPresented();
}

export function setInstructionID(instructionID) {
  Storage.setInstructionID(instructionID);
}

export function setCurrentTextInstructionStepIndex(instructionStepIndex) {
  Storage.setCurrentTextInstructionStepIndex(instructionStepIndex);
}

export function fetchCurrentTextInstructionStepIndex() {
  return parseInt(Storage.fetchCurrentTextInstructionStepIndex());
}

export function setCurrentPDFInstructionPageNumber(pdfInstructionPageNumber) {
    Storage.setCurrentPDFInstructionPageNumber(pdfInstructionPageNumber)
}

export function fetchCurrentPDFInstructionPageNumber() {
  return parseInt(Storage.fetchCurrentPDFInstructionPageNumber());
}

export function setCurrentPDFInstructionZoomLevel(pdfZoomLevel) {
  Storage.setCurrentPDFInstructionZoomLevel(pdfZoomLevel);
}

export function fetchCurrentPDFInstructionZoomLevel() {
  return parseInt(Storage.fetchCurrentPDFInstructionZoomLevel());
}

export function setCurrentVideoTime(time) {
  Storage.setCurrentVideoTime(Math.floor(time));
}

export function fetchCurrentVideoTime() {
  return parseInt(Storage.fetchCurrentVideoTime());
}
