import React from 'react';
import Aux from '../../hoc/Aux/Aux';
import { NavLink } from 'react-router-dom';

const BreadCrumbs = (props) => {

  const breadBrumbsContent = props.breadCrumpObjects.map((breadCrumbObject, index) => {

    const right_angel_icon = index === 0 ? null : <i className="fas fa-angle-right breadcrumb-icon"></i>;
    const spanClasses = index === props.breadCrumpObjects.length - 1 ? "breadcrumb-current-page last-breadcrumb" : "breadcrumb-current-page";

    if (breadCrumbObject.route == null) {
      return (
        <Aux key={index + breadCrumbObject.name}>
          {right_angel_icon}
          <span className={spanClasses}>{breadCrumbObject.name}</span>
        </Aux>
      )
    } else {

      return (
        <Aux key={index + breadCrumbObject.name}>
          {right_angel_icon}
          <NavLink to={breadCrumbObject.route} className="breadcrumb-link">
            <span className={spanClasses}>{breadCrumbObject.name}</span>
          </NavLink>
        </Aux>
      )
    }
  });

  return (
    <p key="breadcrumb-p-tag" className="breadcrumb">
      {breadBrumbsContent}
    </p>
  )
}

export default BreadCrumbs;
