import React from 'react';
import { Link } from 'react-router-dom';
import semnarLogo from '../../assets/resources/img/logo-white.png';
import poweredBySemnarLogo from '../../assets/resources/img/powered-semnar.png';

const CompanyFooter = (props) => {

  const company_logo = props.white_label_object.images.length > 0 && props.white_label_object.active ? props.white_label_object.images[0].file_url : null;
  const backgroundColor = {};

  const footer_class = props.for_instruction ? "semnar-company-footer sticky-bottom semnnar-company-footer-intruction" : "semnar-company-footer sticky-bottom"

  let contact_button = null;

  if (props.product) {
    if (props.product.phone_number.length > 0 || props.product.email.length > 0 || props.product.contact_link.length > 0) {
      contact_button = (
        <div className="col-sm-12 col-md footer-col">
          <Link
          to={'#'}
          onClick={(e) => {
            e.preventDefault()
          }}
          data-toggle="modal"
          data-target="#contactUsModal"
          className="nav-link semnar-company-footer-btn"> Contact Us </Link>
        </div>
      )
    }
  }

  return (
    <footer className={footer_class} style={backgroundColor}>
      <div>
        <div className="row text-center">
          <div className="col-sm-12 col-md-3">
            <img className="semnar-logo" src={company_logo ? company_logo : semnarLogo} alt="Logo" />
          </div>

          <div className="col-sm-12 col-md footer-col">
            <a href={props.white_label_object.company_url} target="_blank" rel="noreferrer" className="semnar-company-footer-btn terms-btn">
              {props.white_label_object.company_readable_url}
            </a>
          </div>
          <div className="col-sm-12 col-md footer-col">
            <Link to={'/privacy_policy'} className="semnar-company-footer-btn terms-btn">Privacy Policy</Link>
          </div>
          <div className="col-sm-12 col-md footer-col">
            <Link to={'/terms_and_conditions'} className="semnar-company-footer-btn terms-btn">Terms & Conditions</Link>
          </div>

          {contact_button}

          <div className="col-sm-12 col-md-3">
            <img className="semnar-logo" src={poweredBySemnarLogo} alt="Logo" />
          </div>
        </div>
      </div>
    </footer>
  );
}

export default CompanyFooter;
