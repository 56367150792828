import React from 'react';


const SafetyMessageIconCard = (props) => {

  const buttonStates = {
    "danger" : {
      text : "DANGER",
      css_class : props.styles.danger,
      icon_element :  <i className={`${"fas fa-exclamation-triangle"} ${props.styles.safety_message_icon}`}></i>,
       },
   "warning" : {
     text : "WARNING",
     css_class : props.styles.warning,
     icon_element :  <i className={`${"fas fa-exclamation-triangle"} ${props.styles.safety_message_icon}`}></i>,
      },
   "caution" : {
    text : "CAUTION",
    css_class : props.styles.caution,
    icon_element :  <i className={`${"fas fa-exclamation-triangle"} ${props.styles.safety_message_icon}`}></i>,
     },
   "notice" : {
     text : "NOTICE",
     css_class : props.styles.notice,
     icon_element : null
      },
  }

  const buttonState = buttonStates[props.safety_type];

  return (
      <div className={`${props.styles.safety_message_icon_card} ${buttonState.css_class}`}>
        <b>
          <p className={`${props.styles.safety_message_icon_text} ${buttonState.css_class}`}>
            { buttonState.icon_element } { buttonState.text }
          </p>
        </b>
      </div>
  )
}

export default SafetyMessageIconCard;
