import React, { Component } from 'react';
import Aux from '../../../hoc/Aux/Aux';
import { getInstructionObjectDetailsShortURI,
         getInstructionReviewsURI,
         getInstructionWithNameObjectDetailsShortURI } from '../../../api/routes';
import { storeUserAction,
         logToConsole,
         fetchUUID,
         isSurveyComplete,
         surveyWasCompleted,
         isPdfPresented,
         pdfPresented,
         pdfNotPresented,
         storeInstructionAction,
         fetchCurrentTextInstructionStepIndex,
         fetchCurrentPDFInstructionPageNumber,
         fetchCurrentPDFInstructionZoomLevel,
         setInstructionID } from '../../../logger/logger';
import axios from 'axios';
import qs from 'qs';

import noImage from '../../../assets/resources/img/no-image.png';

import InstructionTopContent from './InstructionTopContent/InstructionTopContent';
import InstructionTopContentPlaceholder from './InstructionTopContentPlaceholder/InstructionTopContentPlaceholder';
import InstructionBottomContent from './InstructionBottomContent/InstructionBottomContent';
import InstructionBottomContentPlaceholder from './InstructionBottomContentPlaceholder/InstructionBottomContentPlaceholder';
import TextInstruction from './TextInstruction/TextInstruction';
import VideoInstruction from './VideoInstruction/VideoInstruction';
import SinglePagePDFViewer from './PdfInstruction/single-page'
import BreadCrumbs from '../../../components/BreadCrumbs/BreadCrumbs';
import Nav from '../../../components/Nav/Nav';
import Footer from '../../../components/Footer/Footer';
import CompanyNav from '../../../components/CompanyNav/CompanyNav';
import CompanyFooter from '../../../components/CompanyFooter/CompanyFooter';
import ContentInactive from '../../../components/ContentInactive/ContentInactive';
import SurveyModal from './SurveyModal/SurveyModal';
import ContactUsModal from '../../../components/ContactUsModal/ContactUsModal';

import styles from './Instruction.module.css';

const MemoizedSurveyModal = React.memo(SurveyModal);

class Instruction extends Component {

  state = {
    fetched_instruction_object: false,
    instruction_object_not_found: false,
    allow_feedback: false,
    tab_in_focus: false,
    instruction_object_id: this.props.match.params.id,
    company_name: this.props.match.params.company_name,
    product_name: this.props.match.params.product_name,
    instruction_id: this.props.match.params.instruction_id,
    instruction_object: null,
    current_instruction_version: null,
    product: null,
    company: null,
    text_instruction: null,
    video_instruction: null,
    pdf_instruction: null,
    show_fallback_pdf: false,
    white_label_object: null,
    step_language_objects: {},
    video_language_objects: {},
    current_type: qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).type,
    // current_type: "pdf",
    url_lang: qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).lang,
    product_code: qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).product_code
  }

  componentDidMount () {

    window.scrollTo(0, 0);
    window.addEventListener("focus", this.onFocus)
    window.addEventListener('blur', this.onBlur);
    this.fetchInstructionObject();
    storeUserAction("page_start", window.location.href);
  }

  componentWillUnmount() {
    window.removeEventListener("focus", this.onFocus);
  }

  fetchFocusObject = () => {
    if (this.state.current_instruction_version && this.state.instruction_object) {

      if (this.state.current_type === "text") {
        // === gather action information for text instruction ===
        const textInstructionSteps = this.state.instruction_object.text_instruction.text_instruction_steps.sort((a,b)=> (a.position > b.position ? 1 : -1));

        const current_text_instruction_index = fetchCurrentTextInstructionStepIndex();
        const is_feedback_step = current_text_instruction_index + 1 === textInstructionSteps.length + 1;

        return {
          instruction_type: this.state.current_type,
          current_language: this.state.url_lang,
          current_text_instruction_step_index: fetchCurrentTextInstructionStepIndex(),
          current_text_instruction_step_id: !is_feedback_step ? textInstructionSteps[fetchCurrentTextInstructionStepIndex()].id : null,
          is_feedback_step: is_feedback_step
        }
      } else if (this.state.current_type === "pdf") {
        // === gather action information for pdf instruction ===

        return {
          instruction_type: this.state.current_type,
          current_language: this.state.url_lang,
          current_pdf_page_number: isPdfPresented() ? fetchCurrentPDFInstructionPageNumber() : null,
          current_pdf_zoom_level: null,
          pdf_instruction_id: this.state.pdf_instruction.id
        }
      }

    }

    return null;
  }

  onFocus = () => {

    const obj = this.fetchFocusObject();

    if (obj !== null) {
      storeInstructionAction(
        "view_in_focus",
        window.location.href,
        this.state.instruction_object.id,
        obj
      );
    }
  }

  onBlur = () => {

    const obj = this.fetchFocusObject();

    if (obj !== null) {
      storeInstructionAction(
        "view_out_of_focus",
        window.location.href,
        this.state.instruction_object.id,
        obj
      );
    }
  }

  fetchInstructionObject = () => {

    let requestURL = "";

    if (this.state.instruction_object_id !== undefined) {
      requestURL = getInstructionObjectDetailsShortURI(this.state.instruction_object_id);
    } else {
      requestURL = getInstructionWithNameObjectDetailsShortURI(this.state.instruction_id, this.state.product_name, this.state.company_name);
    }


    if (this.state.product_code) {
      if (requestURL.includes("?")) {
        requestURL = requestURL + "&product_code=" + this.state.product_code;
      } else {
        requestURL = requestURL + "?product_code=" + this.state.product_code;
      }
    }


    axios.get(requestURL)
         .then(response => {
           if (response.data.status === "success") {
             console.log("instruction response: ", response);
             const language_objects = this.organizeLanguageObjects(response.data.result.instruction_object.instruction_language_objects);

             let current_language = this.state.url_lang;
             if (current_language === undefined || !(current_language in language_objects)) {
               current_language = ("en" in language_objects) ? "en" : response.data.result.product.product_language_objects[0].language_code;
             }

             const pdf_language_objects = this.organizeLanguageObjects(response.data.result.instruction_object.pdf_instruction.pdf_language_objects);

             let current_type = this.state.current_type;

             if (current_type === undefined || current_type === "" || !this.isCurrentTypeAvailable(current_type, response.data.result.instruction_object)) {
               current_type = this.defaultInstructionType(response.data.result.instruction_object);
             }


             // === store instruction_object id ===
             setInstructionID(response.data.result.instruction_object.id);

             this.setState({
               fetched_instruction_object: true,
               allow_feedback: !isSurveyComplete(),
               instruction_object: response.data.result.instruction_object,
               product: response.data.result.instruction_object.product,
               company: response.data.result.instruction_object.product.company,
               white_label_object: response.data.result.instruction_object.product.company.white_label_object,
               language_objects: language_objects,
               current_language: current_language,
               step_language_objects: this.organizeStepLanguageObjects(response.data.result.instruction_object.text_instruction.text_instruction_steps),
               text_instruction: response.data.result.instruction_object.text_instruction,
               video_instruction: response.data.result.instruction_object.video_instruction,
               video_language_objects: this.organizeLanguageObjects(response.data.result.instruction_object.video_instruction.video_language_objects),
               pdf_instruction: response.data.result.instruction_object.pdf_instruction,
               pdf_language_objects: this.organizeLanguageObjects(response.data.result.instruction_object.pdf_instruction.pdf_language_objects),
               current_instruction_version: response.data.result.instruction_object.current_instruction_version,
               url_lang: current_language,
               current_type: current_type
             }, function() {
               this.setURL();
             });

             if (response.data.result.instruction_object) {
               const instruction_object_id = response.data.result.instruction_object.id;
               this.pageStartHandler(instruction_object_id);
             }

           } else {

             if (response.data.error_type === "MISSING_OBJECT") {
               this.setState({
                 fetched_instruction_object: true,
                 instruction_object_not_found: true
               })
             }

             logToConsole("there was a problem fetching the instruction object: ", response);
             //todo: handle error
           }

         })
         .catch(error => {
           logToConsole("error while fetching instruction object: ", error.message);
           //todo: handle error
         })
  }

  setURL = () => {
    var currentPathName = window.location.href;

    if (this.state.white_label_object.active) {
      // === switch to path for white label if path is currently different ===
      if (currentPathName.includes("/instructions")) {
        const newPath = '/' + this.state.company.name.split(' ').join('_') + '/' + this.state.product.name.split(' ').join('_') + '/' + this.state.instruction_object.id + this.fetchURLArguments();
        this.props.history.push(newPath);
        return;
      }

    } else if (this.props.match.params.id === undefined) {
      // === switch to standard path if id is not in current route ===
      const newPath = '/instructions/' + this.state.instruction_object.id + this.fetchURLArguments();
      this.props.history.push(newPath);
      return;
    }

    const type = qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).type;
    const lang = qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).lang;

    const typeIsInvalid = type === undefined || type !== this.state.current_type;
    const langIsInvalid = lang === undefined || lang !== this.state.current_language;

    if (typeIsInvalid || langIsInvalid) {
      var newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + '?type=' + this.state.current_type + "&lang=" + this.state.current_language;
      if (this.state.product_code) {
        newurl = newurl + "&product_code=" + this.state.product_code;
      }
      window.history.pushState({path:newurl},'',newurl);
    }

  }

  fetchURLArguments = () => {
    var string = "";

    string = '?type=' + this.state.current_type + "&lang=" + this.state.current_language;

    if (this.state.product_code) {
      string = string + "&product_code=" + this.state.product_code;
    }

    return string;
  }

  fetchProductURL = () => {

    if (this.state.white_label_object.active) {
      return '/' + this.state.company.name.split(' ').join('_') + '/' + this.state.product.name.split(' ').join('_');
    } else if (this.props.match.params.id === undefined || this.props.match.params.id !== this.state.product.id) {
      return '/products/' + this.state.product.id;
    } else  {
      return '/products/' + this.props.match.params.id;
    }
  }

  organizeLanguageObjects = (objects) => {

    let language_objects = {};

    for (var i = 0; i < objects.length; i++) {
      const languageObject = objects[i];

      language_objects[languageObject.language_code] = languageObject;
    }

    return language_objects;
  }

  organizeStepLanguageObjects = (text_instruction_steps) => {

    let obj = {};

    for (var i = 0; i < text_instruction_steps.length; i++) {
      const text_instruction_step = text_instruction_steps[i];
      const step_language_objects = text_instruction_step.step_language_objects;

      let language_objects = {};

      for (var j = 0; j < step_language_objects.length; j++) {
        const languageObject = step_language_objects[j];
        language_objects[languageObject.language_code] = languageObject;
      }

      obj[text_instruction_step.id] = language_objects;
    }

    return obj;
  }

  currentLanguageUpdatedHandler = (language_code) => {
    this.setState({current_language: language_code}, function() {
      this.setURL();
    });
  }

  saveInstructionAction = (action_type, url, instruction_object_id, obj) => {
    obj.instruction_type = this.state.current_type;
    storeInstructionAction(action_type, url, instruction_object_id, obj);
  }

  updateInstructionType = (type) => {
    if (type === this.state.current_type) {
      return;
    }
    const newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + '?type=' + type + "&lang=" + this.state.current_language;
    window.history.pushState({path:newurl},'',newurl);
    this.setState({current_type: type});

    const textInstructionSteps = this.state.instruction_object.text_instruction.text_instruction_steps.sort((a,b)=> (a.position > b.position ? 1 : -1));

    const current_text_instruction_index = fetchCurrentTextInstructionStepIndex();
    const is_feedback_step = current_text_instruction_index + 1 === textInstructionSteps.length + 1;

    this.saveInstructionAction(
      "instruction_type_selection",
      window.location.href,
      this.state.instruction_object.id,
      {"instruction_type_selected" : type,
      current_text_instruction_step_index: type === "text" ? 0 : null,
      current_text_instruction_step_id: type === "text" && !is_feedback_step ? textInstructionSteps[0].id : null,
      is_feedback_step: is_feedback_step
      });
  }

  isCurrentTypeAvailable = (type, instruction_step) => {

    if (type === "pdf" && !(this.arePDFInstructionsAvailable(instruction_step))) {
      return false;
    }

    if (type === "text" && !(this.areTextInstructionsAvailable(instruction_step))) {
      return false;
    }

    if (type === "video" && !(this.areVideoInstructionsAvailable(instruction_step))) {
      return false;
    }

    if (type === "pdf" || type === "video" || type === "text") {
      return true;
    }

    return false;
  }

  defaultInstructionType = (instruction_object) => {
    if (this.arePDFInstructionsAvailable(instruction_object)) {
      return "pdf";
    } else if (this.areTextInstructionsAvailable(instruction_object)) {
      return "text";
    } else if (this.areVideoInstructionsAvailable(instruction_object)) {
      return "video";
    }

    return "";
  }

  areTextInstructionsAvailable = (instruction_object) => {
    return instruction_object.text_instruction.text_instruction_steps.length > 0;
  }

  areVideoInstructionsAvailable = (instruction_object) => {
    return instruction_object.video_instruction.video_url !== null;
  }

  arePDFInstructionsAvailable = (instruction_object) => {
    for (var i = 0; i < instruction_object.pdf_instruction.pdf_language_objects.length; i++) {
      const language_object = instruction_object.pdf_instruction.pdf_language_objects[i];
      if (language_object.file_url !== null) {
        return true;
      }
    }

    return false;
  }

  getTextInstructionButton = (type) => {
    if (!this.areTextInstructionsAvailable(this.state.instruction_object)) {
      return null;
    }

    const instruction_option_link_active = styles.instruction_option_link + " " + styles.instruction_option_active;
    const instruction_option_inactive = styles.instruction_option_link;

    const classNames = type === "text" ? instruction_option_link_active : instruction_option_inactive;
    const button = <button onClick={() => this.updateInstructionType("text")}  className={classNames}><i className="far fa-file-alt"></i> Text Instruction </button>
    return button;
  }

  getVideoInstructionButton = (type) => {
    if (!this.areVideoInstructionsAvailable(this.state.instruction_object)) {
      return null;
    }

    const instruction_option_link_active = styles.instruction_option_link + " " + styles.instruction_option_active;
    const instruction_option_inactive = styles.instruction_option_link;

    const classNames = type === "video" ? instruction_option_link_active : instruction_option_inactive;
    const button =  <button id={styles.video_instruction_id} onClick={() => this.updateInstructionType("video")} className={classNames}><i className="far fa-play-circle"></i> Video Instruction </button>
    return button;
  }

  getInstructionTypesArray = () => {
    let types = [];

    if (this.arePDFInstructionsAvailable(this.state.instruction_object)) {
      types.push("pdf");
    }

    if (this.areTextInstructionsAvailable(this.state.instruction_object)) {
      types.push("text");
    }

    if (this.areVideoInstructionsAvailable(this.state.instruction_object)) {
      types.push("video");
    }

    return types;
  }

  getPdfInstructionButton = (type) => {
    if (!this.arePDFInstructionsAvailable(this.state.instruction_object)) {
      return null;
    }

    const instruction_option_link_active = styles.instruction_option_link + " " + styles.instruction_option_active;
    const instruction_option_inactive = styles.instruction_option_link;

    const classNames = type === "pdf" ? instruction_option_link_active : instruction_option_inactive;
    const button = <button id={styles.video_instruction_id} onClick={() => this.updateInstructionType("pdf")} className={classNames}><i className="far fa-file-pdf"></i> PDF Instruction </button>
    return button;
  }

  pageStartHandler = (instruction_version_id) => {
    const obj = this.fetchFocusObject()

    if (obj !== null) {
      this.saveInstructionAction("page_start", window.location.href, this.state.instruction_object.id, obj);
    }
  }

  nextButtonPressedHandler = (current_index, next_index, next_unique_tag) => {

    const meta_data = {current_index: current_index, next_index: next_index, next_unique_tag: next_unique_tag};

    this.saveInstructionAction(
      "next_instruction_step_button_pressed",
      window.location.href,
      this.state.instruction_object.id,
      meta_data);
  }

  previousButtonPressedHandler = (current_index, next_index, next_unique_tag) => {

    const meta_data = {current_index: current_index, next_index: next_index, next_unique_tag: next_unique_tag};

    this.saveInstructionAction(
      "previous_instruction_step_button_pressed",
      window.location.href,
      this.state.instruction_object.id,
      meta_data);
  }

  instructionStepImageSeletectedHandler = (image_index, image_url, current_text_instruction_step, current_text_instruction_step_index) => {

    const meta_data = {
      "image_index": image_index,
      "image_url": image_url,
      "current_text_instruction_step_id" : current_text_instruction_step.id,
      "current_text_instruction_step_index" : current_text_instruction_step_index};

    this.saveInstructionAction(
      "instruction_step_image_selected",
      window.location.href,
      this.state.instruction_object.id,
      meta_data);
  }

  imageNavigatorActionHandler = (obj) => {
    this.saveInstructionAction(
      "image_navigation_button_selected",
      window.location.href,
      this.state.instruction_object.id,
      obj);
  }

  presentSurveyModalHandler = () => {

    const obj = this.fetchFocusObject();

    if (obj !== null) {
      this.saveInstructionAction(
        "present_survey_button_pressed",
        window.location.href,
        this.state.instruction_object.id,
        obj);
    }
  }

  surveyModalClosedHandler = () => {

    const obj = this.fetchFocusObject();

    if (obj !== null) {
      this.saveInstructionAction(
        "survey_modal_closed",
        window.location.href,
        this.state.instruction_object.id,
        obj);
    }
  }

  imageModalClosedHandler = (current_text_instruction_step, current_text_instruction_step_index) => {
    const obj = {
      "current_text_instruction_step_id" : current_text_instruction_step.id,
      "current_text_instruction_step_index" : current_text_instruction_step_index
    }
    this.saveInstructionAction(
      "image_modal_closed",
      window.location.href,
      this.state.instruction_object.id,
      obj);
  }

  hideModalHandler = () => {
    //console.log("MODAL WAS HIDDEN!!!");
  }

  videoActionHandler = (action_type, meta_data) => {
    this.saveInstructionAction(
      action_type,
      window.location.href,
      this.state.instruction_object.id,
      meta_data);
  }

  pdfActionHandler = (action_type, meta_data) => {
    this.saveInstructionAction(
      action_type,
      window.location.href,
      this.state.instruction_object.id,
      meta_data);
  }

  pdfLoadFailedHandler = () => {
    console.log("PDF FAILED");
    this.setState({show_fallback_pdf: true});
  }

  onSurveyComplete = (survey, options) => {

   var obj = {};

   obj["score"] = parseInt(survey.data["nps_score"]);
   obj["result"] = survey.data;
   obj["uuid"] = fetchUUID();

   if (survey.data["user_email"]) {
     obj["email"] = survey.data["user_email"];
   }

   axios.post(getInstructionReviewsURI(), obj, {})
        .then(response => {
          logToConsole("RESPONSE FROM SAVING SURVEY: ", response);
          if (response.data.status === "success") {
            surveyWasCompleted();
            this.setState({allow_feedback: false});
          }

          this.saveInstructionAction(
            "survey_was_submitted",
            window.location.href,
            this.state.instruction_object.id,
            {});

        })
        .catch(error => {
          logToConsole("error while creating user_action: ", error);
        })

  }

  createBreadCrumbObjects = () => {
    if (!this.state.fetched_instruction_object) {
      return [
        {
          name: "Home",
          route: "/"
        }
      ];
    }

    return [
      {
        name: this.state.company.name,
        route: "/companies/" + this.state.company.id
      },
      {
        name: this.state.product.name,
        route: "/products/" + this.state.product.id
      },
      {
        name: this.state.instruction_object.title,
        route: null
      }
    ];
  }

  render () {

     let topContent = <InstructionTopContentPlaceholder styles={styles} />;
     let bottomContent = <InstructionBottomContentPlaceholder styles={styles} />;
     let breadCrumbs = null;
     let instructionPageSectionClasses = "";
     let nav = null;
     let footer = null;
     let fallbackModal = null;

     // === don't show any content until instruction object is retrieved ===
     if (this.state.fetched_instruction_object) {

       if (this.state.instruction_object_not_found) {
         return (
           <ContentInactive object_name={"Instruction"} />
         )
       }

       if (!this.state.white_label_object.active) {
         nav = <Nav forCompany={true} />
         footer = <Footer forCompany={true} />
         breadCrumbs = <BreadCrumbs breadCrumpObjects={this.createBreadCrumbObjects()} />
         instructionPageSectionClasses = styles.instruction_page_section;
       } else {
         nav = <CompanyNav
         white_label_object={this.state.white_label_object}
         for_instruction={true}/>

         footer = <CompanyFooter
         white_label_object={this.state.white_label_object}
         product={this.state.product}
         for_instruction={true}/>

         breadCrumbs = null;
         instructionPageSectionClasses = styles.instruction_page_section_white_label;
       }

       //check query strings
       //let currentInstructionType = this.state.current_type;
       let currentInstructionType = this.state.current_type;

       //main product image
       const productImage = this.state.instruction_object.image? this.state.instruction_object.image.file_url : noImage;

       //company logo
       const companyLogo = this.state.company.image ? <img className={styles.instruction_page_company_logo} src={this.state.company.image.file_url} alt="honda logo" /> : null;

       let categoryElement = null;
       if (this.state.product.category) {
         categoryElement = <p className={styles.product_result_category}>{this.state.product.category.name}</p>
       }

       let instructionObjectToShow = null;

       if (currentInstructionType === "text") {
         const textInstructionSteps = this.state.instruction_object.text_instruction.text_instruction_steps.sort((a,b)=> (a.position > b.position ? 1 : -1));
         instructionObjectToShow = <TextInstruction
                                    styles={styles}
                                    allow_feedback={this.state.allow_feedback}
                                    nextButtonPressed={this.nextButtonPressedHandler}
                                    previousButtonPressed={this.previousButtonPressedHandler}
                                    imageSelected={this.instructionStepImageSeletectedHandler}
                                    imageNavigationAction={this.imageNavigatorActionHandler}
                                    presentSurveyModal={this.presentSurveyModalHandler}
                                    modalClosed={this.imageModalClosedHandler}
                                    step_language_objects={this.state.step_language_objects}
                                    current_language={this.state.current_language}
                                    show_enlarged_photos={this.state.instruction_object.text_instruction.show_enlarged_photos}
                                    text_instruction_steps={textInstructionSteps} />;
       } else if (currentInstructionType === "video") {
         instructionObjectToShow = <VideoInstruction
                                    styles={styles}
                                    allow_feedback={this.state.allow_feedback}
                                    videoAction={this.videoActionHandler}
                                    presentSurveyModal={this.presentSurveyModalHandler}
                                    language_object={this.state.video_language_objects[this.state.current_language]}
                                    video_instruction={this.state.instruction_object.video_instruction}/>
         //currentInstructionType = "video";
       } else if (currentInstructionType === "pdf") {
         const file_url = this.state.pdf_language_objects[this.state.current_language].file_url;
         instructionObjectToShow = <SinglePagePDFViewer
                                    instruction_styles={styles}
                                    storePdfAction={this.pdfActionHandler}
                                    pdf_load_failed={this.pdfLoadFailedHandler}
                                    current_language={this.state.url_lang}
                                    pdf_instruction={this.state.pdf_instruction}
                                    show_fallback_pdf={this.state.show_fallback_pdf}
                                    pdf={file_url} />


          fallbackModal = (
            <div className="modal fade" id={"fallbackmodal"}>
              <div className="modal-dialog modal-xl modal-pdf-one">
                <div className="modal-content modal-pdf">

                  <div className="modal-header">
                    <h4 className="modal-title">View PDF</h4>
                    <button type="button" className="close" data-dismiss="modal">&times;</button>
                  </div>

                  <div className="modal-body">
                    <iframe src={file_url} width="100%" height="100%">
                    </iframe>
                  </div>

                </div>
              </div>
            </div>
          )

       }

       const textInstructionButton = this.getTextInstructionButton(currentInstructionType);
       const videoInstructionButton = this.getVideoInstructionButton(currentInstructionType);
       const pdfInstructionButton = this.getPdfInstructionButton(currentInstructionType);

      topContent = <InstructionTopContent
                      styles={styles}
                      instruction_object={this.state.instruction_object}
                      instruction_types={this.getInstructionTypesArray()}
                      updateInstructionType={this.updateInstructionType}
                      language_object={this.state.language_objects[this.state.current_language]}
                      allow_feedback={this.state.allow_feedback}
                      presentSurveyModal={this.presentSurveyModalHandler}
                      productImage={productImage}
                      productURL={this.fetchProductURL()}
                      companyLogo={companyLogo}
                      product={this.state.product}
                      product_code={this.state.product_code}
                      current_language={this.state.current_language}
                      currentLanguageUpdatedHandler={this.currentLanguageUpdatedHandler}
                      categoryElement={categoryElement}
                      current_type={this.state.current_type}
                      instructionObjectToShow={instructionObjectToShow}
                    />

     bottomContent = <InstructionBottomContent
                      styles={styles}
                      instruction_object={this.state.instruction_object}
                      language_object={this.state.language_objects[this.state.current_language]}
                      current_language={this.state.current_language}
                      currentLanguageUpdatedHandler={this.currentLanguageUpdatedHandler}
                      textInstructionButton={textInstructionButton}
                      videoInstructionButton={videoInstructionButton}
                      pdfInstructionButton={pdfInstructionButton}
                      instructionObjectToShow={instructionObjectToShow}
                    />
     }

    return (

      <Aux>
        {nav}

        <section className={instructionPageSectionClasses}>
          <div className="container">

            <div className="row">
              <div className="col-12">
                {breadCrumbs}
              </div>
            </div>

            <div className={`${'row'} ${styles.instruction_content_row}`}>
              <div className="col-12">

                {topContent}

              </div>

              <div className={`${'col-12'} ${styles.instruction_steps_row}`}>

                {bottomContent}

              </div>
            </div>

          </div>
        </section>

        {footer}

        <MemoizedSurveyModal modalClosed={this.surveyModalClosedHandler} onSurveyComplete={this.onSurveyComplete} />

        {fallbackModal}

        <ContactUsModal product={this.state.product} />

      </Aux>
    );
  }
}

export default Instruction;
