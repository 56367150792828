import React, { useEffect, useRef, useState } from 'react';
import { setCurrentPDFInstructionPageNumber,
         fetchCurrentPDFInstructionPageNumber,
         setCurrentPDFInstructionZoomLevel,
         fetchCurrentPDFInstructionZoomLevel } from '../../logger/logger';
import axios from 'axios';
import fileDownload from 'js-file-download';
import styles from './pdfmodal.module.css';
import { Document, Page } from "react-pdf";
import { pdfjs } from 'react-pdf';
pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const PdfModal = ({ modalStyle, children, show, onClose, backdropStyle, pdf, storeAction, loadFailed }) => {

  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(fetchCurrentPDFInstructionPageNumber());
  const [zoomMultiplier, setZoomMultiplier] = useState(fetchCurrentPDFInstructionZoomLevel());
  const [touchStart, setTouchStart] = useState(null);
  const [touchEnd, setTouchEnd] = useState(null);

  const minSwipeDistance = 80;

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setPageNumber(fetchCurrentPDFInstructionPageNumber());
    setZoomMultiplier(fetchCurrentPDFInstructionZoomLevel());
  }

  function onDocumentLoadFail({error}) {
    console.log('Failed to load file with error: ', error);
    loadFailed();
  }

  function changePage(offset) {
    setPageNumber(prevPageNumber => prevPageNumber + offset);
  }

  function previousPage() {
    setCurrentPDFInstructionPageNumber(pageNumber - 1);
    changePage(-1);
    storeAction("previous_pdf_page_button_pressed");
  }

  function nextPage() {
    setCurrentPDFInstructionPageNumber(pageNumber + 1);
    changePage(1);
    storeAction("next_pdf_page_button_pressed");
  }

  function changeZoom(offset) {
    setZoomMultiplier(prevZoomMultiplier => prevZoomMultiplier + offset);
  }

  function zoomMore() {
    if (zoomMultiplier < 3) {
      setCurrentPDFInstructionZoomLevel(zoomMultiplier + 1);
      changeZoom(1);
      storeAction("pdf_zoom_in");
    }
  }

  function zoomLess() {
    if (zoomMultiplier > 1) {
      setCurrentPDFInstructionZoomLevel(zoomMultiplier - 1);
      changeZoom(-1);
      storeAction("pdf_zoom_out");
    }
  }

  function handleDownload() {
    const url = pdf;
    const filename = "download.pdf";

    axios.get(url, {
      responseType: 'blob',
    })
    .then((res) => {
      fileDownload(res.data, filename);
      storeAction("pdf_download_button_pressed");
    })
  }

  const onTouchStart = (e) => {
    setTouchEnd(null) // otherwise the swipe is fired even with usual touch events
    setTouchStart(e.targetTouches[0].clientX)
  }

  const onTouchMove = (e) => setTouchEnd(e.targetTouches[0].clientX)

  const onTouchEnd = () => {
    if (!touchStart || !touchEnd) return
    const distance = touchStart - touchEnd
    const isLeftSwipe = distance > minSwipeDistance
    const isRightSwipe = distance < -minSwipeDistance

    if (modalRef.current) {

      const viewWidth = (modalRef.current.offsetWidth * 0.75);
      const pageWidth = viewWidth > 600 ? 600 : viewWidth;
      const finalPageWidth = pageWidth * zoomMultiplier;

      if (finalPageWidth <= viewWidth) {
        if (isRightSwipe && pageNumber > 1) {
          previousPage();
        } else if (isLeftSwipe && pageNumber < numPages) {
          nextPage();
        }
      }

    }

  }

  const modalRef = useRef(null);

  useEffect(
      () => {

          if (show) {
              modalRef.current.classList.add(styles.visible);
          }
          else {
              modalRef.current.classList.remove(styles.visible);
          }
      },
      [
          show,
          modalRef.current
      ]
  );

  let pageComponent = null;
  let viewWidth = 0;
  let pageWidth = 0;

  if (modalRef.current) {
    viewWidth = (modalRef.current.offsetWidth * 0.75);
    pageWidth = viewWidth > 600 ? 600 : viewWidth;

    pageComponent =  <Page
                      width={pageWidth * zoomMultiplier}
                      className={styles.custom_pdf_page}
                      pageNumber={pageNumber} />
  }

  return (
        <React.Fragment>
            <div ref={modalRef} style={backdropStyle} className={`${styles.modal__wrap}`}>
            <header className={styles.pdf_nav_header}>
              <nav className="navbar navbar-expand navbar-light" role="navigation">

                <ul className={`navbar-nav ${styles.left_list}`}>

                  <li className="nav-item">
                    <p className={`nav-link ${styles.pdf_nav_item} ${styles.pdf_page_count}`}>
                      {pageNumber || (numPages ? 1 : "--")} / {numPages || "--"}
                    </p>
                  </li>

                  <li className="nav-item">
                    <button className={`nav-link ${styles.pdf_nav_item} ${styles.pdf_nav_arrow_button}`} type="button" disabled={pageNumber <= 1} onClick={previousPage}>
                      <i className={`fas fa-arrow-circle-left ${styles.nav_icon}`} ></i>
                    </button>
                  </li>

                  <li className="nav-item">
                    <button className={`nav-link ${styles.pdf_nav_item} ${styles.pdf_nav_arrow_button}`} type="button" disabled={pageNumber >= numPages} onClick={nextPage}>
                      <i className={`fas fa-arrow-circle-right ${styles.nav_icon}`}></i>
                    </button>
                  </li>

                  <li className="nav-item">
                    <button
                    className={`nav-link ${styles.pdf_nav_item} ${styles.pdf_nav_arrow_button}`}
                    type="button"
                    disabled={zoomMultiplier === 1}
                    onClick={zoomLess}>
                      <i className={`fas fa-search-minus ${styles.nav_icon}`}></i>
                    </button>
                  </li>

                  <li className="nav-item">
                    <button
                    className={`nav-link ${styles.pdf_nav_item} ${styles.pdf_nav_arrow_button}`}
                    type="button"
                    disabled={zoomMultiplier === 3}
                    onClick={zoomMore}>
                      <i className={`fas fa-search-plus ${styles.nav_icon}`}></i>
                    </button>
                  </li>

                </ul>

                <ul className={`navbar-nav ml-auto ${styles.right_list}`}>

                  <li className="nav-item">
                    <button
                    className={`nav-link ${styles.pdf_nav_item} ${styles.pdf_nav_arrow_button}`}
                    type="button"
                    onClick={handleDownload}>
                      <i className={`fas fa-file-download ${styles.nav_icon}`}></i>
                    </button>
                  </li>

                  <li className="nav-item">
                    <button
                    className={`nav-link ${styles.pdf_nav_item} ${styles.pdf_nav_arrow_button}`}
                    type="button"
                    onClick={onClose}>
                      <i className={`fas fa-times-circle ${styles.nav_icon}`}></i>
                    </button>
                  </li>

                </ul>

              </nav>
            </header>

                <div
                onTouchStart={onTouchStart}
                onTouchMove={onTouchMove}
                onTouchEnd={onTouchEnd}
                className={styles.modal_subwrapper}>
                  <div style={{width: pageWidth * zoomMultiplier}} className={`${styles.modal} row`}>
                    <Document
                    file={pdf}
                    onLoadSuccess={onDocumentLoadSuccess}
                    onLoadError={(error) => onDocumentLoadFail(error)}>
                      { pageComponent }
                    </Document>
                  </div>
                </div>

            </div>
        </React.Fragment>
  );
};

export default PdfModal;
