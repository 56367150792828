import React, { useState } from "react";
import { fetchCurrentPDFInstructionPageNumber,
         pdfPresented,
         pdfNotPresented,
         fetchCurrentPDFInstructionZoomLevel} from '../../../../logger/logger';
import PdfModal from '../../../../components/PdfModal/PdfModal';
import PdfViewer from '../../../../components/PdfViewer/PdfViewer';
import InstructionFooter from '../InstructionFooter/InstructionFooter'
import styles from './pdfinstruction.module.css';

export default function SinglePage(props) {

  const [show, setShow] = useState(false);
  const { pdf } = props;

  function pdfMetaData() {
    return {
      instruction_type: "pdf",
      current_language: props.current_language,
      current_pdf_page_number: fetchCurrentPDFInstructionPageNumber(),
      current_pdf_zoom_level: fetchCurrentPDFInstructionZoomLevel(),
      pdf_instruction_id: props.pdf_instruction.id
    }
  }

  function modalOpened() {
    pdfPresented();
    props.storePdfAction("pdf_modal_opened", pdfMetaData());
    setShow(true);
  }

  function modalClosed() {
    pdfNotPresented();
    props.storePdfAction("pdf_modal_closed", pdfMetaData());
    setShow(false);
  }

  function storeAction(action_type) {
    props.storePdfAction(action_type, pdfMetaData())
  }

  function loadFailed() {
    props.pdf_load_failed();
  }

  let content = (
    <p> There is no PDF provided for this language </p>
  )

  let modalContent = null;
  let fallbackModalContent = props.show_fallback_pdf ? <PdfViewer styles={styles} file_url={pdf} modalID={"fallback_modal"} /> : null;


  if (pdf !== null) {
    content = (
      <React.Fragment>
        <button className={`button-primary xSmall button_pulsate ${styles.view_pdf_button}`} onClick={modalOpened}>View PDF Instructions</button>

        { fallbackModalContent }
      </React.Fragment>
    )

    modalContent = (
      <PdfModal
      pdf={pdf}
      show={show}
      storeAction={storeAction}
      loadFailed={loadFailed}
      onClose={modalClosed}>
      </PdfModal>
    )
  }

  return (
    <React.Fragment>

           <div className="row text-center">
            <div className="col">
              { content }
            </div>
           </div>

           { modalContent }
       </React.Fragment>
  )
}
