import React from 'react';
import InstructionBottomContentPlaceholderRow from './InstructionBottomContentPlaceholderRow/InstructionBottomContentPlaceholderRow';

import placeholderImage from '../../../../assets/resources/img/text-content-placeholder2.png';

const InstructionBottomContentPlaceholder = (props) => {

  let rows = [];

  for (var i = 0; i < 8; i++) {
    rows.push(<InstructionBottomContentPlaceholderRow styles={props.styles} image={placeholderImage} key={'placeholder-image-' + i} />);
  }

  return (
    <div className="col-12 text-center">
      {rows}
    </div>
  );
}

export default InstructionBottomContentPlaceholder;
