import React, { Component } from 'react';
import Aux from '../../../hoc/Aux/Aux';
import { getProductDetailsURI, getProductWithNameDetailsURI } from '../../../api/routes';
import { logToConsole, storeUserAction } from '../../../logger/logger';
import axios from 'axios';
import qs from 'qs';

import ProductTopContent from './ProductTopContent/ProductTopContent';
import ProductTopContentPlaceholder from './ProductTopContentPlaceholder/ProductTopContentPlaceholder';
import ProductBottomContent from './ProductBottomContent/ProductBottomContent';
import ProductBottomContentPlaceholder from './ProductBottomContentPlaceholder/ProductBottomContentPlaceholder';
import SupplementalDirectiveContent from './SupplementalDirectiveContent/SupplementalDirectiveContent';
import BreadCrumbs from '../../../components/BreadCrumbs/BreadCrumbs';
import Nav from '../../../components/Nav/Nav';
import Footer from '../../../components/Footer/Footer';
import CompanyNav from '../../../components/CompanyNav/CompanyNav';
import CompanyFooter from '../../../components/CompanyFooter/CompanyFooter';
import ContentInactive from '../../../components/ContentInactive/ContentInactive';
import ContactUsModal from '../../../components/ContactUsModal/ContactUsModal';

import styles from './Product.module.css';

class Product extends Component {

  state = {
    product_id: this.props.match.params.id,
    company_name: this.props.match.params.company_name,
    product_name: this.props.match.params.product_name,
    checked_for_product: false,
    product_not_found:  false,
    product: null,
    company: null,
    white_label_object: null,
    instruction_sections: [],
    current_selected_image_index: 0,
    language_objects: {},
    current_language: "",
    url_lang: qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).lang,
    product_code: qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).product_code
  }

  componentDidMount () {

    window.scrollTo(0, 0);
    storeUserAction("page_start", window.location.href);

    let requestURL = "";

    if (this.state.product_id !== undefined) {
      requestURL = getProductDetailsURI(this.state.product_id);
    } else {
      requestURL = getProductWithNameDetailsURI(this.state.product_name, this.state.company_name);
    }

    if (this.state.product_code) {
      if (requestURL.includes("?")) {
        requestURL = requestURL + "&product_code=" + this.state.product_code;
      } else {
        requestURL = requestURL + "?product_code=" + this.state.product_code;
      }
    }
    axios.get(requestURL)
         .then(response => {
           logToConsole("response from fetching product info", response);
           if (response.data.status === "success") {

            const language_objects = this.organizeLanguageObjects(response.data.result.product.product_language_objects);

            let current_language = this.state.url_lang;
            if (current_language === undefined || !(current_language in language_objects)) {
              current_language = ("en" in language_objects) ? "en" : response.data.result.product.product_language_objects[0].language_code;
            }

            const supplement_directive_language_objects = this.organizeLanguageObjects(response.data.result.product.supp_directive.language_objects);

             this.setState({
               checked_for_product: true,
               product: response.data.result.product,
               company: response.data.result.product.company,
               white_label_object: response.data.result.product.company.white_label_object,
               instruction_objects: response.data.result.product.instruction_objects,
               language_objects: language_objects,
               current_language: current_language,
               supplement_directive_language_objects: supplement_directive_language_objects,
               url_lang: current_language
             }, function() {
               this.setCurrentLanguage();
             })
           } else {
             if (response.data.error_type === "MISSING_OBJECT") {
               this.setState({
                 checked_for_product: true,
                 product_not_found: true
               })
             }
             logToConsole("ran into problem while fetching product info");
             //todo: handle error
           }

         })
         .catch(error => {
           logToConsole("ran into error while fetching product info: ", error);
           //todo: handle error
         })

  }

  organizeLanguageObjects = (objects) => {

    let language_objects = {};

    for (var i = 0; i < objects.length; i++) {
      const languageObject = objects[i];

      language_objects[languageObject.language_code] = languageObject;
    }

    return language_objects;
  }

  setCurrentLanguage = () => {

    var currentPathName = window.location.href;

    if (this.state.white_label_object.active) {
      if (currentPathName.includes("/products")) {
        this.props.history.push('/' + this.state.company.name.split(' ').join('_') + '/' + this.state.product.name.split(' ').join('_') + this.fetchURLArguments());
      }
    } else if (this.props.match.params.id === undefined) {
      this.props.history.push('/products/' + this.state.product.id + this.fetchURLArguments());
    }

    const lang = qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).lang;
    const langIsInvalid = lang === undefined || lang !== this.state.current_language;

    if (langIsInvalid) {
      var newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + '?lang=' + this.state.language_objects[this.state.current_language].language_code;

      if (this.state.product_code) {
        newurl = newurl + "&product_code=" + this.state.product_code;
      }

      window.history.pushState({path:newurl},'',newurl);
    }

  }

  fetchURLArguments = () => {
    var string = "";

    string = "?&lang=" + this.state.current_language;

    if (this.state.product_code) {
      string = string + "&product_code=" + this.state.product_code;
    }

    return string;
  }

  currentURLForLink = () => {
    if (this.state.white_label_object.active) {
      return '/' + this.state.company.name.split(' ').join('_') + '/' + this.state.product.name.split(' ').join('_') + '/';
    } else {
      return '/instructions/';
    }
  }

  createBreadCrumbObjects = () => {
    if (!this.state.checked_for_product || this.state.product_not_found === true) {
      return [
        {
          name: "Home",
          route: "/"
        }
      ]
    }

    return [
      {
        name: "Company List",
        route: "/company_directory"
      },
      {
        name: this.state.company.name,
        route: "/companies/" + this.state.company.id
      },
      {
        name: this.state.product.name,
        route: null
      }
    ];
  }

  currentLanguageUpdatedHandler = (language_code) => {
    this.setState({current_language: language_code}, function() {
      this.setCurrentLanguage();
    });
  }

  orderInstructionObjects = (instruction_objects) => {
    return instruction_objects.sort((a,b)=> (a.position > b.position ? 1 : -1));
  }

  imageSelectedHandler = (index) => {
    this.setState({current_selected_image_index: index});
  }

  imageNavigatorActionHandler = (object) => {
    //todo: store this object as a user_action
  }

  updateCurrentImageHandler = (index) => {
    this.setState({current_selected_image_index: index});
  }

  modalClosed = () => {
    // NOTE: nothing needs to happen here
  }

  render () {

    let topContent = <ProductTopContentPlaceholder styles={styles} />;
    let bottomContent = <ProductBottomContentPlaceholder styles={styles} />;
    let supplementalDirectiveContent = null;
    let breadCrumbs = <BreadCrumbs breadCrumpObjects={this.createBreadCrumbObjects()} />;
    let language_selection_content = null;
    let nav = null;
    let footer = null;
    let productProfileSectionClasses = null;

    if (this.state.checked_for_product && this.state.product_not_found) {
      return (
        <ContentInactive object_name={"Product"}/>
      )
    }

    if (this.state.checked_for_product) {

      if (!this.state.white_label_object.active) {
        nav = <Nav forCompany={true} />
        footer = <Footer forCompany={true} />
        productProfileSectionClasses = styles.product_profile_section
      } else {
        nav = <CompanyNav white_label_object={this.state.white_label_object}/>
        footer = <CompanyFooter product={this.state.product} white_label_object={this.state.white_label_object} />
        productProfileSectionClasses = styles.product_profile_section_white_label;
        breadCrumbs = null;
      }

      if (this.state.product.product_language_objects.length > 1) {
        const languageOptions = this.state.product.product_language_objects.map((language_object) => {
          return <option key={"language_option_" + language_object.language_code} value={language_object.language_code}>{language_object.language}</option>;
        });

        language_selection_content = (
          <div className="row">
            <div className="col-sm-12 col-md-4 text-center">
              <form className="text-center">
                <div className="form-group">
                  <select
                  className="form-control"
                  id="current_language"
                  value={this.state.current_language}
                  onChange={(event) => this.currentLanguageUpdatedHandler(event.target.value)}>
                    {languageOptions}
                  </select>
                </div>
              </form>
            </div>
          </div>
        )
      }

      topContent = <ProductTopContent
                    styles={styles}
                    product={this.state.product}
                    company={this.state.company}
                    white_label_object={this.state.white_label_object}
                    product_code={this.state.product_code}
                    language_content={language_selection_content}
                    totalInstructions={this.state.product.total_instructions}
                    language_object={this.state.language_objects[this.state.current_language]}
                    imageSelectedHandler={this.imageSelectedHandler} />;

      bottomContent = <ProductBottomContent
                       styles={styles}
                       instruction_url={this.currentURLForLink()}
                       current_language={this.state.current_language}
                       product_code={this.state.product_code}
                       instruction_objects={this.orderInstructionObjects(this.state.instruction_objects)} />;

      supplementalDirectiveContent = <SupplementalDirectiveContent
                                      styles={styles}
                                      language_object={this.state.supplement_directive_language_objects[this.state.current_language]}
                                      />
    }

    return (

      <Aux>

      {nav}

      <section className={productProfileSectionClasses}>
        <div className="container">
          <div className="row">
            <div className="col">
              {breadCrumbs}
            </div>
          </div>

          <div className="row">
            <div className="col-12">
              <div className={`${'semnar-card default'}`}>
                <div className="card-body">

                  {topContent}

                </div>
              </div>
            </div>
          </div>

          {supplementalDirectiveContent}

          <div className={`${'row'} ${styles.instructions_row}`}>
            <div className="col">
              <h3>Instructions</h3>
            </div>

            <div className="col">
              {/*<input className="form-control mr-sm-2 semnar-search-bar instructions_search_bar" type="search" placeholder="Search Instructions" aria-label="Search" />*/}
            </div>
          </div>

          <div className={`${'row'} ${styles.instruction_section_row}`}>

            {bottomContent}

          </div>
        </div>

      </section>

      {footer}

      <ContactUsModal product={this.state.product} />

      </Aux>
    );
  }
}

export default Product;
