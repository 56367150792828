/**
 * @flow
 * @format
 */

import Cookies from 'js-cookie';
import { v4 as uuid } from 'uuid';

const SESSION_KEY = '__session';

//uuid constants for user_actions
const UUID_KEY = 'uuid';
const UUID_TIMESTAMP_KEY = 'uuid_time';

//uuid constants for instruction_actions
const INSTRUCTION_UUID_KEY = "instruction_uuid";
const INSTRUCTION_UUID_TIMESTAMP_KEY = "instruction_uuid_time";

//constants for storing survey completion state
const SURVEY_NOT_COMPLETED = "no";
const SURVEY_COMPLETED = "yes";
const SURVEY_COMPLETED_KEY = "survey_completed";

//constants for storing pdf modal presentation status
const PDF_NOT_PRESENTED = "no";
const PDF_PRESENTED = "yes";
const PDF_PRESENTED_KEY = "pdf_presented";

//constant for storing instruction state info
const CURRENT_INSTRUCTION_ID_KEY = "current_instruction_id";
const CURRENT_TEXT_INSTRUCTION_STEP_KEY = "current_text_instruction_step_index";
const CURRENT_PDF_INSTRUCTION_PAGE_KEY = "current_pdf_instruction_page_index";
const CURRENT_PDF_INSTRUCTION_ZOOM_KEY = "current_pdf_instruction_zoom_level";
const CURRENT_VIDEO_TIME_KEY = "current_video_time";

// ms * sec * minutes
const UUIDInvalidationTime = 1000 * 60 * 30;

/**
 * Helper class to handle storage of tokens.
 */
class Storage {
  static get<T>(): ?T {
    return Cookies.get(SESSION_KEY) ?? null;
  }

  static set<T>(data: T): T {
    Cookies.set(SESSION_KEY, data);
    return data;
  }

  /**
   * Writes to localStorage
   */
  static write<T>(key: string, data: T): T {
    const localStorage = window.localStorage;
    localStorage.setItem(key, data);
    return data;
  }

  /**
   * Fetches from localStorage
   */
  static fetch<T = mixed>(key: string): ?T {
    const localStorage = window.localStorage;
    return localStorage.getItem(key) ?? null;
  }

  static remove(): void {
    Cookies.remove(SESSION_KEY);
  }

  static refreshUUID(): void {
    Cookies.set(UUID_TIMESTAMP_KEY, Date.now());
  }

  /**
   * Creates a new UUID
   */
  static createUUID() {
    return uuid();
  }

  static uuid(): string {
    const currentUUID = Cookies.get(UUID_KEY);
    const UUIDTime = Cookies.get(UUID_TIMESTAMP_KEY);
    const now = Date.now();

    if (currentUUID != null && UUIDTime != null && now < UUIDInvalidationTime + parseInt(UUIDTime, 10)) {
      return currentUUID;
    }

    const id = Storage.createUUID();
    Cookies.set(UUID_KEY, id);
    Cookies.set(UUID_TIMESTAMP_KEY, now);
    Cookies.set(SURVEY_COMPLETED_KEY, SURVEY_NOT_COMPLETED);
    // === reset the current instruction ===
    Storage.setInstructionID(-1);

    return id;
  }

  static instructionUUID(): string {
    const currentUUID = Cookies.get(INSTRUCTION_UUID_KEY);
    const UUIDTime = Cookies.get(INSTRUCTION_UUID_TIMESTAMP_KEY);
    const now = Date.now();
    if (
      currentUUID != null &&
      UUIDTime != null &&
      now < UUIDInvalidationTime + parseInt(UUIDTime, 10)
    ) {
      return currentUUID;
    }

    const id = Storage.createUUID();
    Cookies.set(INSTRUCTION_UUID_KEY, id);
    Cookies.set(INSTRUCTION_UUID_TIMESTAMP_KEY, now);
    return id;
  }

  static setInstructionID(instructionID) {
    const currentInstructionID = Cookies.get(CURRENT_INSTRUCTION_ID_KEY);

    if (currentInstructionID === undefined || parseInt(currentInstructionID) !== instructionID) {
      Cookies.set(CURRENT_INSTRUCTION_ID_KEY, instructionID);
      Cookies.set(CURRENT_TEXT_INSTRUCTION_STEP_KEY, 0);
      Cookies.set(CURRENT_PDF_INSTRUCTION_PAGE_KEY, 1);
      Cookies.set(CURRENT_PDF_INSTRUCTION_ZOOM_KEY, 1);
      Cookies.set(CURRENT_VIDEO_TIME_KEY, 0);
    }
  }

  static fetchCurrentTextInstructionStepIndex() {
    var currentTextInstructionStep = Cookies.get(CURRENT_TEXT_INSTRUCTION_STEP_KEY);

    if (currentTextInstructionStep === undefined) {
      Cookies.set(CURRENT_TEXT_INSTRUCTION_STEP_KEY, 0);
      currentTextInstructionStep = Cookies.get(CURRENT_TEXT_INSTRUCTION_STEP_KEY)
    }

    return currentTextInstructionStep;
  }

  static setCurrentTextInstructionStepIndex(instructionStepIndex) {
    Cookies.set(CURRENT_TEXT_INSTRUCTION_STEP_KEY, instructionStepIndex);
  }

  static fetchCurrentPDFInstructionPageNumber() {
    var currentPDFInstructionPage = Cookies.get(CURRENT_PDF_INSTRUCTION_PAGE_KEY);

    if (currentPDFInstructionPage === undefined) {
      Cookies.set(CURRENT_PDF_INSTRUCTION_PAGE_KEY, 1);
      currentPDFInstructionPage = Cookies.get(CURRENT_PDF_INSTRUCTION_PAGE_KEY)
    }

    return currentPDFInstructionPage;
  }

  static setCurrentPDFInstructionPageNumber(PdfPageNumber) {
    Cookies.set(CURRENT_PDF_INSTRUCTION_PAGE_KEY, PdfPageNumber);
  }

  static fetchCurrentPDFInstructionZoomLevel() {
    var currentPDFInstructionZoomLevel = Cookies.get(CURRENT_PDF_INSTRUCTION_ZOOM_KEY);

    if (currentPDFInstructionZoomLevel === undefined) {
      Cookies.set(CURRENT_PDF_INSTRUCTION_ZOOM_KEY, 1);
      currentPDFInstructionZoomLevel = Cookies.get(CURRENT_PDF_INSTRUCTION_ZOOM_KEY)
    }

    return currentPDFInstructionZoomLevel;
  }

  static setCurrentPDFInstructionZoomLevel(pdfZoomLevel) {
    Cookies.set(CURRENT_PDF_INSTRUCTION_ZOOM_KEY, pdfZoomLevel);
  }

  static fetchCurrentVideoTime() {
    var currentVideoTime = Cookies.get(CURRENT_VIDEO_TIME_KEY);

    if (currentVideoTime === undefined) {
      Cookies.set(CURRENT_VIDEO_TIME_KEY, 0);
      currentVideoTime = Cookies.get(CURRENT_VIDEO_TIME_KEY)
    }

    return currentVideoTime;
  }

  static setCurrentVideoTime(time) {
    Cookies.set(CURRENT_VIDEO_TIME_KEY, time);
  }

  static isSurveyComplete(): string {
    var survey_complete = Cookies.get(SURVEY_COMPLETED_KEY);

    if (survey_complete == null) {
      Cookies.set(SURVEY_COMPLETED_KEY, SURVEY_NOT_COMPLETED);
      survey_complete = Cookies.get(SURVEY_COMPLETED_KEY);
    }

    return survey_complete === SURVEY_NOT_COMPLETED ? false : true;
  }

  static surveyWasCompleted() {
    Cookies.set(SURVEY_COMPLETED_KEY, SURVEY_COMPLETED);
  }

  static isPdfPresented(): string {
    var pdf_presented = Cookies.get(PDF_PRESENTED_KEY);

    if (pdf_presented == null) {
      Cookies.set(PDF_PRESENTED_KEY, PDF_NOT_PRESENTED);
      pdf_presented = Cookies.get(PDF_PRESENTED_KEY);
    }

    return pdf_presented === PDF_NOT_PRESENTED ? false : true;
  }

  static pdfNotPresented() {
    Cookies.set(PDF_PRESENTED_KEY, PDF_NOT_PRESENTED);
  }

  static pdfPresented() {
    Cookies.set(PDF_PRESENTED_KEY, PDF_PRESENTED);
  }
}

export default Storage;
