import React, { Component } from 'react';

import { BrowserRouter } from 'react-router-dom';

import Semnar from './containers/Semnar/Semnar';

class App extends Component {
  render() {
    return (
      <BrowserRouter>
      <div className="App">
        <Semnar />
      </div>
      </BrowserRouter>
    );
  }
}

export default App;
