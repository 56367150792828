import React from 'react';

const InstructionRightContentPlaceholderRow = (props) => {

  return (
    <div className="row">
      <div className="col-12">
        <img className={props.styles.instruction_bottom_content_image_placeholder} src={props.image} alt="instruction placeholder"/>
      </div>
    </div>
  );
}

export default InstructionRightContentPlaceholderRow;
