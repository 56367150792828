import React, { Component } from 'react';

import styles from './ContactUsModal.module.css';

class ContactUsModal extends Component {

  state = {}

  render () {

    let phone_number_content = null;
    let email_content = null;
    let contact_link_content = null;

    if (this.props.product) {
      if (this.props.product.phone_number) {
        phone_number_content = (
          <div className="row">
            <div className="col-12">
              <p><i className={`${'fas fa-phone'} ${styles.contact_us_icon}`}></i>  {this.props.product.phone_number}</p>
            </div>
          </div>
        )
      }

      if (this.props.product.email) {
        email_content = (
          <div className="row">
            <div className="col-12">
              <p><i className={`${'fas fa-envelope'} ${styles.contact_us_icon}`}></i>  {this.props.product.email}</p>
            </div>
          </div>
        )
      }

      if (this.props.product.contact_link) {
        contact_link_content = (
          <div className="row">
            <div className="col-12">
            <i className={`${'fas fa-link'} ${styles.contact_us_icon}`}></i>
            <a href={this.props.product.contact_link} target="_blank" rel="noreferrer" className="semnar-company-footer-btn">
             Visit Support Page
            </a>
            </div>
          </div>
        )
      }
    }

    return (
      <div className="modal" id="contactUsModal">
        <div className="modal-dialog">
          <div className="modal-content">

            <div className="modal-header">
              <h4 className="modal-title">Contact Us</h4>
              <button type="button" className="close" data-dismiss="modal">&times;</button>
            </div>

            <div className="modal-body">
              {phone_number_content}
              {email_content}
              {contact_link_content}
            </div>

          </div>
        </div>
      </div>
    )

  }
}

export default ContactUsModal;
