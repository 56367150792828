import React from 'react';
import Aux from '../../hoc/Aux/Aux';

const PdfViewer = (props) => {

  return (
    <Aux>
      <div className="text-center">
        <button className={props.styles.fallback_button} data-toggle="modal" data-target={"#fallbackmodal"}> PDF Not Loading? </button>
      </div>

    </Aux>
  );
}

export default PdfViewer;
