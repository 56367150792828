import React from 'react';

import SafetyMessageIconCard from './SafetyMessageIconCard/SafetyMessageIconCard';

import styles from './SafetyMessagePreview.module.css';

const SafetyMessagePreview = (props) => {

  return (
      <div className={`${styles.safety_message_card} ${styles.default}`}>

        <div className={`${"card-body text-center"} ${styles.default} ${styles.safety_message_card_body}`}>

          <SafetyMessageIconCard
            safety_message={props.safety_message}
            safety_type={props.safety_type}
            styles={styles} />

          <p className={ styles.safety_message_text }>
            { props.message }
          </p>
        </div>

      </div>
  )
}

export default SafetyMessagePreview;
