import React from 'react';

import useWindowDimensions from '../UseWindowDimension/UseWindowDimension';

const ImageGrid = (props) => {

    const { height, width } = useWindowDimensions();

    function orderedImages() {
          let do_any_positions_match = false;
          let images = props.images;
      
          for (var i = 0; i < images.length; i++) {
            const image = images[i];
      
            for (var j = 0; j < images.length; j++) {
              const image_two = images[j];
      
              if (image.id !== image_two.id && image.position === image_two.position) {
                do_any_positions_match = true;
              }
            }
          }
      
          if (do_any_positions_match === true) {
            // === if any of the positions match use the id's of the objects to determine their order ===
      
            var new_array = images;
      
            for (let i = 0; i < new_array.length; i++) {
              for (let j = 0; j < new_array.length - i - 1; j++) {
                  if (new_array[j + 1].id < new_array[j].id) {
                      [new_array[j + 1], new_array[j]] = [new_array[j], new_array[j + 1]];
                  }
              }
            }
      
            return new_array;
          } else {
            return images.sort((a, b) => (a.position > b.position) ? 1 : (a.position === b.position) ? ((a.position > b.position) ? 1 : -1) : -1 );
          }
    }

    function buttonWasPressed(index) {
        props.image_selected(index);
    }

    // === not being used currently ===
    function fourColumns() {
        var column1 = [];
        var column2 = [];
        var column3 = [];
        var column4 = [];
    
        var currentColumn = 1;
        const images = orderedImages();
    
        for (var i = 0; i < images.length; i++) {
            const currentIndex = i;
            const currentImage = images[i];
            const imageElement = (
                <button 
                    key={"button_" + i}
                    className={props.styles.enlarged_image_button}
                    onClick={() => buttonWasPressed(currentIndex)} >
                    <img key={"image_" + i} src={currentImage.file_url}/>
                </button>
            )
    
            if (currentColumn === 1) {
                column1.push(imageElement);
                currentColumn += 1;
            } else if (currentColumn === 2) {
                column2.push(imageElement);
                currentColumn += 1;
            } else if (currentColumn === 3) {
                column3.push(imageElement);
                currentColumn += 1;
            } else {
                column4.push(imageElement);
                currentColumn = 1;
            }
        }

        return (
            <React.Fragment>
                <div className={props.styles.image_grid_column}>
                {column1}
                </div>
                <div className={props.styles.image_grid_column}>
                    {column2}
                </div>
                <div className={props.styles.image_grid_column}>
                    {column3}
                </div>
                <div className={props.styles.image_grid_column}>
                    {column4}
                </div>
            </React.Fragment>
        )
    }
    
    // === not being used currently ===
    function twoColumns() {
        var column1 = [];
        var column2 = [];

        var currentColumn = 1;
        const images = orderedImages();

    
        for (var i = 0; i < images.length; i++) {
            const currentIndex = i;
            const currentImage = images[i];
            const imageElement = (
                <button 
                    key={"button_" + i}
                    className={props.styles.enlarged_image_button}
                    onClick={() => buttonWasPressed(currentIndex)} >
                    <img key={"image_" + i} src={currentImage.file_url}/>
                </button>
            )
    
            if (currentColumn === 1) {
                column1.push(imageElement);
                currentColumn += 1;
            } else {
                column2.push(imageElement);
                currentColumn = 1;
            } 
        }

        return (
            <React.Fragment>
                <div className={props.styles.image_grid_column}>
                    {column1}
                </div>
                <div className={props.styles.image_grid_column}>
                    {column2}
                </div>
            </React.Fragment>
        )
    }

    function oneColumn() {
        var column1 = [];

        const images = orderedImages();

        for (var i = 0; i < images.length; i++) {
            const currentIndex = i;
            const currentImage = images[i];
            const imageElement = (
                <button 
                    key={"button_" + i}
                    className={props.styles.enlarged_image_button}
                    onClick={() => buttonWasPressed(currentIndex)} >
                    <img key={"image_" + i} src={currentImage.file_url}/>
                </button>
            )

            column1.push(imageElement);
        }

        return (
            <React.Fragment>
                <div className={props.styles.image_grid_column}>
                    {column1}
                </div>
            </React.Fragment>
        )
    }

    let grid_content = null;

    if (width > 600) {
        grid_content = oneColumn();
    } else {
        grid_content = oneColumn();
    }

  return (
    <div className={`${props.styles.image_grid_row} text-center`}>
        {grid_content}
    </div>
  );
}

export default ImageGrid;
