import React from 'react';

import productImagePlaceholder from '../../../../assets/resources/img/content-result-placeholder.png';
import productInfoPlaceholder from '../../../../assets/resources/img/content-result-placeholder2.jpg';

const ProductTopContentPlaceholder = (props) => {

  return (
    <div className="row">
      <div className="col-12 col-md-5">
        <div className="row">
          <div className="col-12">
            <img className={props.styles.product_main_image} src={productImagePlaceholder} alt="Product placeholder" />
          </div>
        </div>

      </div>

      <div className="col-12 col-md-7">
        <div className="row">
            <img className={props.styles.product_top_content_placeholder} src={productInfoPlaceholder} alt="Product info placeholder" />
        </div>
      </div>
    </div>
  );
}

export default ProductTopContentPlaceholder;
