import React from 'react';
import Aux from '../../hoc/Aux/Aux';
import styles from './CompleteSurveyView.module.css';

const CompleteSurveyView = (props) => {

  var body_text = null;

  if (props.allow_feedback) {
    body_text = (
      <Aux>
        <p className={ styles.survey_view_card_text }>
          Help make these instructions better by telling us about your experience!
        </p>

        <button
        className="button-primary xSmall button_pulsate"
        onClick={props.present_survey}
        data-toggle="modal"
        data-target="#surveyModal"> Take Survey
        </button>
      </ Aux>
    )
  } else {
    body_text = (
      <p className={ styles.survey_view_card_complete_text }>
        Your feedback has been saved! Thank you for helping us continue to make this experience better for everyone.
      </p>

    )
  }

  return (
      <div className={`${styles.survey_view_card} ${styles.default}`}>

        <div className={`${"card-body text-center"} ${styles.default} ${styles.survey_view_card_body}`}>

          <h2 className={styles.survey_view_card_title}> Feedback </h2>

          {body_text}

        </div>

      </div>
  )
}

export default CompleteSurveyView;
