import React, { Component } from 'react';
import Aux from '../../../../hoc/Aux/Aux';
import { NavLink } from 'react-router-dom';

class InstructionTopContent extends Component {

  handleClick = (e) => {

    if (this.props.product_code) {
      e.preventDefault();
    }
  }

  showProductContent = () => {

    if (!this.props.instruction_object.unlisted) {
      return (
        <div className={`${'col-12'} ${this.props.styles.hide_when_small}`}>
          <NavLink
          to={this.props.productURL + "?lang=" + this.props.current_language}
          onClick={this.handleClick}
          className="button-primary small">
            View Product
          </NavLink>
        </div>
      )
    }

    return null;
  }

  viewAllProductsContent = () => {

    if (!this.props.instruction_object.unlisted) {
      return (
        <div className="col-12 text-center">
          <a
          href={this.props.productURL + "?lang=" + this.props.current_language}
          onClick={this.handleClick}
          className="button-primary small">
            View All Instructions
          </a>
        </div>
      )
    }

    return null;
  }

  render () {
    var previewLinkAlertContent = null;

    if (this.props.product_code) {
      previewLinkAlertContent = (
          <div className="col-12">
            <div className="alert alert-warning">
              <strong>This is a preview link!</strong> Only share this page with people you know.
            </div>
          </div>
      )
    }


    let instruction_type_dropdown = null;

    if (this.props.instruction_types.length > 1) {

      const instruction_type_options = this.props.instruction_types.map((instruction_type, index) => {
        return <option key={"instruction_type_" + index} value={instruction_type}>{instruction_type} instructions</option>;
      });

      instruction_type_dropdown = (
        <form style={{margin: "0 auto"}} className="text-center">
          <div className={`${'form-group'}`}>
            <select
            className="form-control"
            id="current_type"
            value={this.props.current_type}
            onChange={(event) => this.props.updateInstructionType(event.target.value)}>
              {instruction_type_options}
            </select>
          </div>
        </form>
      )

    }

    let language_objects = {};

    for (var i = 0; i < this.props.product.product_language_objects.length; i++) {
      const languageObject = this.props.product.product_language_objects[i];

      language_objects[languageObject.language_code] = languageObject;
    }

    let language_object = {};

    if ((this.props.current_language in language_objects)) {
      language_object = language_objects[this.props.current_language];
    } else if (("en" in language_objects)) {
      language_object = language_objects["en"];
    } else {
      language_object = this.props.product.product_language_objects[0];
    }

    const languageOptions = this.props.instruction_object.instruction_language_objects.map((language_object) => {
      return <option key={"language_option_" + language_object.language_code} value={language_object.language_code}>{language_object.language}</option>;
    });

    var feedback_button_content = null;

    if (this.props.allow_feedback) {
      feedback_button_content = (
        <li className={`${'list-group-item'} ${this.props.styles.instruction_info_list_item}`}>
          <i className={`${'fas fa-star'} ${this.props.styles.instruction_info_icon}`}></i>
          <span className={`${'d-none d-md-block'} ${this.props.styles.list_group_title}`}> Survey </span>
          <span className={this.props.styles.list_group_value}>
            <button
            className="button-primary xSmall button_pulsate"
            onClick={this.props.presentSurveyModal}
            data-toggle="modal"
            data-target="#surveyModal"> Take Survey
            </button>
          </span>
        </li>
      );
    }

    let preview_link_content = null;

    if (this.props.product.preview_link.length > 0) {
      preview_link_content = (
        <li className={`${'list-group-item'} ${this.props.styles.instruction_info_list_item}`}>
          <i className={`${'fas fa-link'} ${this.props.styles.instruction_info_icon}`}></i>
          <span className={`${'d-none d-md-block'} ${this.props.styles.list_group_title}`}> Visit Product </span>
          <span className={this.props.styles.list_group_value}>
            <a href={this.props.product.preview_link} target="_blank" className={this.props.styles.visit_product_link}>Click Here</a>
          </span>
        </li>
      )
    }

    let top_instruction_content = null;
    let bottom_instruction_content = null;
    let top_info_content_classes = ""

    if (this.props.current_type === "pdf") {
      top_instruction_content = this.props.instructionObjectToShow;
      top_info_content_classes = "col-12 col-md-7";
    } else {
      bottom_instruction_content = this.props.instructionObjectToShow;
      top_info_content_classes = "col-12 col-md-7 d-none";
    }

    return (

      <Aux>

        <div className={`${'row'} ${this.props.styles.back_to_product_breadcrumb}`}>
          <NavLink
          to={this.props.productURL + "?lang=" + this.props.current_language}
          onClick={this.handleClick}
          className={this.props.styles.back_to_product_breadcrumb_link}>
            <i className={`${'fas fa-chevron-left'} ${this.props.styles.back_to_product_breadcrumb_icon}`}></i> View Product
          </NavLink>
        </div>

        <div className="semnar-card default">

          <div className="card-body">

            <div className="row">

              {previewLinkAlertContent}

              <div className={`${'col'}`}>
                <h1>{this.props.language_object.title}</h1>
              </div>
            </div>

            <hr/>

            <div className="row">

              <div className="col-12 col-md-5 text-center">

                <div className={`${'row'} ${this.props.styles.view_all_instructions_row}`}>

                  <div className="col-12">
                    <img className={this.props.styles.company_card_product_image} src={this.props.productImage} alt="Instruction Preview" />
                  </div>

                  {this.showProductContent()}

                  <div className={`${"col-12 text-center"} ${this.props.styles.small_screen_pdf_button}`}>
                    {instruction_type_dropdown}
                  </div>

                  <div className={`${'col-12'} ${this.props.styles.small_screen_pdf_button}`}>
                    { top_instruction_content }
                  </div>

                </div>

              </div>

              <div className={`${'col-12 col-md-7'} ${this.props.styles.hide_when_small}`}>

                <ul className="list-group list-group-flush">
                  <li className={`${'list-group-item'} ${this.props.styles.instruction_info_list_item}`}>
                    <i className={`${'far fa-file-alt'} ${this.props.styles.instruction_info_icon}`}></i>
                    <span className={`${'d-none d-md-block'} ${this.props.styles.list_group_title}`}> Product </span>
                    <span className={this.props.styles.list_group_value}>
                      {language_object.name}
                    </span>
                  </li>

                  {preview_link_content}

                  <li className={`${'list-group-item'} ${this.props.styles.instruction_info_list_item}`}>
                    <i className={`${'fas fa-list'} ${this.props.styles.instruction_info_icon}`}></i>
                    <span className={`${'d-none d-md-block'} ${this.props.styles.list_group_title}`}> Category </span>
                    <span className={this.props.styles.list_group_value}>
                      {this.props.product.category ? this.props.product.category.name : "N/A"}
                    </span>
                  </li>

                  <li className={`${'list-group-item'} ${this.props.styles.instruction_info_list_item}`}>
                    <i className={`${'fas fa-tachometer-alt'} ${this.props.styles.instruction_info_icon}`}></i>
                    <span className={`${'d-none d-md-block'} ${this.props.styles.list_group_title}`}> Difficulty </span>
                    <span className={this.props.styles.list_group_value}>
                      {this.props.instruction_object.difficulty}
                    </span>
                  </li>

                  <li className={`${'list-group-item'} ${this.props.styles.instruction_info_list_item}`}>
                    <i className={`${'far fa-clock'} ${this.props.styles.instruction_info_icon}`}></i>
                    <span className={`${'d-none d-md-block'} ${this.props.styles.list_group_title}`}> Duration </span>
                    <span className={this.props.styles.list_group_value}>
                      {this.props.instruction_object.estimated_time} minute(s)
                    </span>
                  </li>

                  <li className={`${'list-group-item'} ${this.props.styles.instruction_info_list_item}`}>
                    <i className={`${'fas fa-language'} ${this.props.styles.instruction_info_icon}`}></i>
                    <span className={`${'d-none d-md-block'} ${this.props.styles.list_group_title}`}> Language </span>
                    <span className={this.props.styles.list_group_value}>
                      <form className="text-center">
                        <div className={`${'form-group'} ${this.props.styles.list_group_value_form_group}`}>
                          <select
                          className="form-control"
                          id="current_language"
                          value={this.props.current_language}
                          onChange={(event) => this.props.currentLanguageUpdatedHandler(event.target.value)}>
                            {languageOptions}
                          </select>
                        </div>
                      </form>
                    </span>
                  </li>

                  {feedback_button_content}

                </ul>

              </div>

              <div className={`${top_info_content_classes} ${this.props.styles.small_screen_pdf_button}`}>

                <ul className="list-group list-group-flush">
                  <li className={`${'list-group-item'} ${this.props.styles.instruction_info_list_item}`}>
                    <i className={`${'far fa-file-alt'} ${this.props.styles.instruction_info_icon}`}></i>
                    <span className={`${'d-none d-md-block'} ${this.props.styles.list_group_title}`}> Product </span>
                    <span className={this.props.styles.list_group_value}>
                      {language_object.name}
                    </span>
                  </li>

                  {preview_link_content}

                  <li className={`${'list-group-item'} ${this.props.styles.instruction_info_list_item}`}>
                    <i className={`${'fas fa-list'} ${this.props.styles.instruction_info_icon}`}></i>
                    <span className={`${'d-none d-md-block'} ${this.props.styles.list_group_title}`}> Category </span>
                    <span className={this.props.styles.list_group_value}>
                      {this.props.product.category ? this.props.product.category.name : "N/A"}
                    </span>
                  </li>

                  <li className={`${'list-group-item'} ${this.props.styles.instruction_info_list_item}`}>
                    <i className={`${'fas fa-tachometer-alt'} ${this.props.styles.instruction_info_icon}`}></i>
                    <span className={`${'d-none d-md-block'} ${this.props.styles.list_group_title}`}> Difficulty </span>
                    <span className={this.props.styles.list_group_value}>
                      {this.props.instruction_object.difficulty}
                    </span>
                  </li>

                  <li className={`${'list-group-item'} ${this.props.styles.instruction_info_list_item}`}>
                    <i className={`${'far fa-clock'} ${this.props.styles.instruction_info_icon}`}></i>
                    <span className={`${'d-none d-md-block'} ${this.props.styles.list_group_title}`}> Duration </span>
                    <span className={this.props.styles.list_group_value}>
                      {this.props.instruction_object.estimated_time} minute(s)
                    </span>
                  </li>

                  <li className={`${'list-group-item'} ${this.props.styles.instruction_info_list_item}`}>
                    <i className={`${'fas fa-language'} ${this.props.styles.instruction_info_icon}`}></i>
                    <span className={`${'d-none d-md-block'} ${this.props.styles.list_group_title}`}> Language </span>
                    <span className={this.props.styles.list_group_value}>
                      <form className="text-center">
                        <div className={`${'form-group'} ${this.props.styles.list_group_value_form_group}`}>
                          <select
                          className="form-control"
                          id="current_language"
                          value={this.props.current_language}
                          onChange={(event) => this.props.currentLanguageUpdatedHandler(event.target.value)}>
                            {languageOptions}
                          </select>
                        </div>
                      </form>
                    </span>
                  </li>

                  {feedback_button_content}

                </ul>

              </div>

            </div>

          </div>
        </div>

        <div style={{marginTop: "12px"}} className={`${'col-12'} ${this.props.styles.small_screen_pdf_button}`}>
          { bottom_instruction_content }
        </div>

        <div className={`${this.props.styles.instruction_title_mobile}`}>
          <h1>{this.props.language_object.title}</h1>
        </div>


        <div className="modal fade" id="instructionMobileModal">
          <div className="modal-dialog">
            <div className="modal-content">

              <div className="modal-header">
                <h4 className="modal-title">
                  {this.props.language_object.title}
                </h4>
                <button type="button" className="close" data-dismiss="modal">&times;</button>
              </div>

              <div className="modal-body">

                <div className={`${'row'} ${this.props.styles.view_all_instructions_row}`}>

                  <div className="col-12">
                    <img className={this.props.styles.company_card_product_image} src={this.props.productImage} alt="Instruction Preview" />
                  </div>

                  {this.viewAllProductsContent()}

                </div>


                <div className="row">
                  <div className="col-12 col-md-7">

                    <ul className="list-group list-group-flush">
                      <li className={`${'list-group-item'} ${this.props.styles.instruction_info_list_item}`}>
                        <i className={`${'far fa-file-alt'} ${this.props.styles.instruction_info_icon}`}></i>
                        <span className={`${'d-none d-md-block'} ${this.props.styles.list_group_title}`}> Product </span>
                        <span className={this.props.styles.list_group_value}>
                          {language_object.name}
                        </span>
                      </li>

                      <li className={`${'list-group-item'} ${this.props.styles.instruction_info_list_item}`}>
                        <i className={`${'fas fa-list'} ${this.props.styles.instruction_info_icon}`}></i>
                        <span className={`${'d-none d-md-block'} ${this.props.styles.list_group_title}`}> Category </span>
                        <span className={this.props.styles.list_group_value}>
                          {this.props.product.category ? this.props.product.category.name : "N/A"}
                        </span>
                      </li>

                      <li className={`${'list-group-item'} ${this.props.styles.instruction_info_list_item}`}>
                        <i className={`${'fas fa-tachometer-alt'} ${this.props.styles.instruction_info_icon}`}></i>
                        <span className={`${'d-none d-md-block'} ${this.props.styles.list_group_title}`}> Difficulty </span>
                        <span className={this.props.styles.list_group_value}>
                          {this.props.instruction_object.difficulty}
                        </span>
                      </li>

                      <li className={`${'list-group-item'} ${this.props.styles.instruction_info_list_item}`}>
                        <i className={`${'far fa-clock'} ${this.props.styles.instruction_info_icon}`}></i>
                        <span className={`${'d-none d-md-block'} ${this.props.styles.list_group_title}`}> Duration </span>
                        <span className={this.props.styles.list_group_value}>
                          {this.props.instruction_object.estimated_time} minute(s)
                        </span>
                      </li>

                      <li className={`${'list-group-item'} ${this.props.styles.instruction_info_list_item}`}>
                        <i className={`${'fas fa-language'} ${this.props.styles.instruction_info_icon}`}></i>
                        <span className={`${'d-none d-md-block'} ${this.props.styles.list_group_title}`}> Language </span>
                        <span className={this.props.styles.list_group_value}>
                          <form className="text-center">
                            <div className={`${'form-group'} ${this.props.styles.list_group_value_form_group}`}>
                              <select
                              className="form-control"
                              id="current_language"
                              value={this.props.current_language}
                              onChange={(event) => this.props.currentLanguageUpdatedHandler(event.target.value)}>
                                {languageOptions}
                              </select>
                            </div>
                          </form>
                        </span>
                      </li>

                    </ul>

                  </div>
                </div>



              </div>

            </div>
          </div>
        </div>

      </Aux>
    );
  }

}

export default InstructionTopContent;
