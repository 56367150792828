import React from 'react';

import productImagePlaceholder from '../../../../assets/resources/img/content-result-placeholder2.jpg';

const InstructionTopContentPlaceholder = (props) => {

  return (
    <img className={props.styles.instruction_page_top_content_img_placeholder} src={productImagePlaceholder} alt="company content placeholder" />
  );
}

export default InstructionTopContentPlaceholder;
