/**
 * @flow
 * @format
 */

const CURRENT_ENV = "production";
const SERVER = 'api';
const VERSION = 'v1';

const BASE_API_URI = `/${SERVER}/${VERSION}`;
const PRODUCTION_BASE_API_URI = `https://api.semnar.com/${SERVER}/${VERSION}`;
const TEST_BASE_API_URI = `https://staging.api.semnar.com/${SERVER}/${VERSION}`;
const BASE_PAGE_URI = '';

export const USE_MOCK_URI = 'use_mock';

/**
 * Page Routes
 */
function pageURI(rest: string): string {
  return `${BASE_PAGE_URI}${rest}`;
}

export function getCompaniesPageURI(): string {
  return pageURI('/companies');
}

export function getCompanyPageURI(companyID: ID): string {
  return pageURI(`/companies/${companyID}`);
}

export function getProductPageURI(companyID: ID, productID: ID): string {
  return pageURI(`/companies/${companyID}/products/${productID}`);
}

export function getLoginPageURI(): string {
  return pageURI(`/login`);
}

export function getQrCodeObjectDetailsURI(qrCodeObjectID: ID): string {
  return apiURI(`/qr_code_objects/${qrCodeObjectID}?unique_code=true`);
}

export function getAwsBucketURI(): string {
  if (CURRENT_ENV === "dev") {
    return "https://dev-semnar-asset-bucket.s3-us-west-2.amazonaws.com/";
  } else if (CURRENT_ENV === "test") {
    return "https://test-semnar-asset-bucket.s3-us-west-2.amazonaws.com/";
  } else if(CURRENT_ENV === "production") {
    return "https://production-semnar-asset-bucket.s3-us-west-2.amazonaws.com/"
  }
}

export function getAdminHomeURI() {

  if (CURRENT_ENV === "production") {
    return `http://admin.semnar.com/admin/products`;
  } else if (CURRENT_ENV === "test") {
    return `http://staging.admin.semnar.com/admin/products`;
  } else if (CURRENT_ENV === "dev") {
    return "http://localhost:3002/admin/products";
  }
}

export function getAdminLoginURI() {

  if (CURRENT_ENV === "production") {
    return `http://admin.semnar.com/login`;
  } else if (CURRENT_ENV === "test") {
    return `http://staging.admin.semnar.com/login`;
  } else if (CURRENT_ENV === "dev") {
    return "http://localhost:3002/login";
  }
}

export function getInstructionDetailPageURI(
  companyID: ID,
  productID: ID,
  instructionID: ID,
  mode: string,
  instructionModeID: ?ID,
): string {
  return pageURI(
    `${getProductPageURI(
      companyID,
      productID,
    )}/instructions/${instructionID}/${mode}/${instructionModeID ?? ''}`,
  );
}


/**
 * API Routes
 */
function apiURI(rest: string): string {

  if (CURRENT_ENV === "production") {
    return `${PRODUCTION_BASE_API_URI}${rest}`
  } else if (CURRENT_ENV === "test") {
    return `${TEST_BASE_API_URI}${rest}`;
  } else if (CURRENT_ENV === "dev") {
    return `${BASE_API_URI}${rest}`;
  }
}

export function getUserURI(userID): string {
  return apiURI(`/users/${userID}`);
}

export function getUserActionsURI(): string {
  return apiURI(`/user_actions`);
}

export function getInstructionActionsURI(): string {
  return apiURI(`/instruction_actions`);
}

export function getInstructionReviewsURI(): string {
  return apiURI(`/instruction_reviews`);
}

export function getCompaniesURI(): string {
  return apiURI(`/companies`);
}

export function getCompanyProductsURI(companyID: ID): string {
  return apiURI(`/companies/${companyID}/products`);
}

export function getCompanyDetailsURI(companyID: ID): string {
  return apiURI(`/companies/${companyID}`);
}

export function getCompanyWithNameDetailsURI(companyName: ID): string {
  return apiURI(`/companies/${companyName}/?search_by_name=true`);
}

export function getProductDetailsURI(productID: ID): string {
  return apiURI(`/products/${productID}`);
}

export function getProductWithNameDetailsURI(productName: ID, companyName: ID): string {
  return apiURI(`/products/${productName}?company_name=${companyName}`);
}

export function getProductInstructionGroupsURI(productID: ID): string {
  return apiURI(`/products/${productID}/instruction_sections`);
}

export function getCompanyImagesDetailsURI(companyID: ID): string {
  return apiURI(`/companies/${companyID}/images`);
}

export function getCompanyImageDetailsURI(companyID: ID, imageID: ID): string {
  return apiURI(`/companies/${companyID}/images/${imageID}`);
}

export function getProductSearchURI(): string {
  return apiURI(`/search`);
}

export function getInstructionTextStepsURI(
  instructionObjectID: ID,
  textInstructionID: ID,
): string {
  return apiURI(
    `/instruction_objects/${instructionObjectID}/text_instructions/${textInstructionID}`,
  );
}

export function getInstructionVideoURI(
  instructionObjectID: ID,
  videoInstructionID: ID,
): string {
  return apiURI(
    `/instruction_objects/${instructionObjectID}/video_instructions/${videoInstructionID}`,
  );
}

export function getInstructionObjectDetailsShortURI(instructionObjectID: ID): string {
  return apiURI(`/instruction_objects/${instructionObjectID}`);
}

export function getInstructionWithNameObjectDetailsShortURI(instructionID: ID, productName: ID, companyName: ID): string {
  return apiURI(`/instruction_objects/${instructionID}?product_name=${productName}&company_name=${companyName}`);
}

export function getSearchResultsURI(): string {
  return USE_MOCK_URI;
}

/**
 * Authentication Routes
 */
export function getLoginURI(): string {
  return apiURI(`/user_token`);
}

export function getVerifyEmailURI(token: ID): string {
  return apiURI(`/verify_email?token=${token}`);
}

export function getResetEmailURI(token: ID): string {
  return apiURI(`/reset_email?token=${token}`);
}

export function getResetPasswordURI(): string {
  return apiURI(`/reset_password`);
}

export function getResendVerifyEmailURI(): string {
  return apiURI(`/send_verify_email`);
}

export function getSendResetPasswordLinkURI(): string {
  return apiURI(`/forgot_password`);
}
