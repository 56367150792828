import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import Aux from '../../hoc/Aux/Aux';

import Nav from '../../components/Nav/Nav';
import Footer from '../../components/Footer/Footer';
import Product from './Product/Product';
import Instruction from './Instruction/Instruction';
import QrCode from './QrCode/QrCode';
import TermsAndConditions from './TermsAndConditions/TermsAndConditions';
import PrivacyPolicy from './PrivacyPolicy/PrivacyPolicy';
import NotFound from './NotFound/NotFound';

//import Company from './Company/Company';
//import CompanyDirectory from './CompanyDirectory/CompanyDirectory';
//import LandingPage from './LandingPage/LandingPage';
//import Search from './Search/Search';
//import AboutUs from './AboutUs/AboutUs';
//import ContactUs from './ContactUs/ContactUs';

class Semnar extends Component {

  render() {
    return (
      <Aux>
        <Nav />
        <Switch>

          <Route path="/products/:id" exact component={Product} />
          <Route path="/instructions/:id" exact component={Instruction} />
          <Route path="/qr_codes/:id" exact component={QrCode} />
          <Route path="/:company_name/:product_name" exact component={Product} />
          <Route path="/:company_name/:product_name/:instruction_id" exact component={Instruction} />
          <Route path="/terms_and_conditions" exact component={TermsAndConditions} />
          <Route path="/privacy_policy" exact component={PrivacyPolicy} />

          <Route component={NotFound} />

        </Switch>
      </ Aux>
    );
  }
}

export default Semnar;

// <Route path="/search" exact component={Search} />
// <Route path="/companies/:id" exact component={Company} />
// <Route path="/company_directory" exact component={CompanyDirectory} />
// <Route path="/about_us" exact component={AboutUs} />
// <Route path="/contact_us" exact component={ContactUs} />
// <Route path="/:company_name" exact component={Company} />
// <Route path="/:company_name/:product_name" exact component={Product} />
// <Route path="/:company_name/:product_name/:instruction_id" exact component={Instruction} />
