import React from 'react';

import InstructionCard from './InstructionCard/InstructionCard';

const ProductBottomContent = (props) => {

  const instruction_objects = props.instruction_objects.map((instruction_object, index) => {
    return <InstructionCard
            styles={props.styles}
            instruction_url={props.instruction_url}
            key={"instruction_row_" + instruction_object.id + "_" + index}
            current_language={props.current_language}
            product_code={props.product_code}
            instruction_object={instruction_object} />
  });

  return instruction_objects;
}

export default ProductBottomContent;
