import React from 'react';
import { useLocation, Link } from 'react-router-dom';
import semnarLogo from '../../assets/resources/img/logo-white.png';

const Footer = (props) => {
  const location = useLocation();
  const pathToInclude = [ "/terms_and_conditions", "/privacy_policy"];

  if (!pathToInclude.includes(location.pathname) && !props.forCompany) {
    return null;
  }

  return (
    <footer className="semnar-footer sticky-bottom">
          <div className="container text-center">

            <div className="row">
              <div className="d-none d-md-block col-sm-12 col-md-2">
                <img className="semnar-logo" src={semnarLogo} alt="Logo" />
              </div>
              <div className="col-sm-12 col-md">
              </div>
              {/*

                <div className="col-sm-12 col-md footer-col">
                  <Link to={'/'} className="nav-link semnar-footer-btn"> Home </Link>
                </div>
                <div className="col-sm-12 col-md footer-col">
                  <Link to={'/about_us'} className="nav-link semnar-footer-btn"> About Us </Link>
                </div>
                <div className="col-sm-12 col-md footer-col">
                  <Link to={'/contact_us'} className="nav-link semnar-footer-btn"> Contact Us </Link>
                </div>
                <div className="col-sm-12 col-md footer-col">
                </div>
                <div className="col-sm-12 col-md footer-col">
                  <a href='https://twitter.com/semnar_inc' target="_blank" rel="noreferrer" className="social-icon"><i className="fab fa-twitter"></i></a>

                  <a href='https://www.facebook.com/semnarInc' target="_blank" rel="noreferrer" className="social-icon"><i className="fab fa-facebook-f"></i></a>

                  <a href='https://www.instagram.com/semnar_inc/' target="_blank" rel="noreferrer" className="social-icon"><i className="fab fa-instagram"></i></a>
                </div>

              */}
            </div>

            <hr />

            <div className="row">
              <div className="col-sm-12 col-md copyright-text">
                <p > Copyright <span>&#169;</span> 2021 Semnar Inc. </p>
              </div>
              <div className="col-sm-12 col-md">
                  <Link to={'/terms_and_conditions'} className="semnar-footer-btn terms-btn">Terms & Conditions</Link>
                  <Link to={'/privacy_policy'} className="semnar-footer-btn terms-btn">Privacy Policy</Link>
              </div>
            </div>

          </div>
    </footer>
  );
}

export default Footer;
