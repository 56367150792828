import React from 'react';
import ProductBottomContentPlaceholderRow from './ProductBottomContentPlaceholderRow/ProductBottomContentPlaceholderRow';

import placeholderImage from '../../../../assets/resources/img/text-content-placeholder2.png';

const ProductBottomContentPlaceholder = (props) => {

  let rows = [];

  for (var i = 0; i < 12; i++) {
    rows.push(<ProductBottomContentPlaceholderRow styles={props.styles} image={placeholderImage} key={'placeholder-image-' + i} />);
  }

  return (
    <div className="col-12 text-center">
      {rows}
    </div>
  );
}

export default ProductBottomContentPlaceholder;
